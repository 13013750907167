import { useTranslation } from "react-i18next"
import colors from "../../service/constants"
import { relativeHeight, relativeWidth } from "../../service/utilities"
import Text from "../global/Text"
import LeaderboardAll from "./LeaderboardAll"
import LeaderboardTop3 from "./LeaderboardTop3"

const LeaderboardCard = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        width: relativeWidth(394),
        minWidth: relativeWidth(394),
        height: "100%",
        borderRadius: relativeWidth(20),
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
        backgroundColor: colors.background,
        display: "flex",
        flexDirection: "column",
        paddingTop: relativeHeight(18),
        paddingInline: relativeWidth(22),
      }}
    >
      <Text
        fontSize={relativeWidth(34)}
        fontWeight={700}
        lineHeight={relativeHeight(64)}
        color={colors.primary}
      >
        {t("leaderboard")}
      </Text>
      <LeaderboardTop3 />
      <LeaderboardAll />
    </div>
  )
}

export default LeaderboardCard
