import { Stack } from "@mui/material"
import "../../styles/scrollingFooter.css"
import ScrollingFooterItem from "./ScrollingFooterItem"
import { relativeHeight } from "../../service/utilities"
import footerItems from "../../service/footerItems"

const ScrollingFooter = () => {
  // scrolling speed
  const speed = 300000

  return (
    <Stack
      alignItems="center"
      direction="row"
      style={{
        width: "100%",
        height: relativeHeight(62),
        overflow: "hidden",
        position: "fixed",
        bottom: 0,
        left: 0,
        backgroundColor: "#FFFFFF",
        boxShadow: "4px 0px 24px 0px rgba(17, 67, 97, 0.1)",
      }}
    >
      <section
        style={{
          display: "flex",
          animation: `swipe ${speed}ms linear infinite backwards`,
          height: "100%",
        }}
      >
        {footerItems.map((item) => (
          <ScrollingFooterItem
            key={item.id}
            actionId={item.id}
            hint={item.hint}
          />
        ))}
      </section>
      <section
        style={{
          display: "flex",
          animation: `swipe ${speed}ms linear infinite backwards`,
          height: "100%",
        }}
      >
        {footerItems.map((item) => (
          <ScrollingFooterItem
            key={item.id}
            actionId={item.id}
            hint={item.hint}
          />
        ))}
      </section>
      <section
        style={{
          display: "flex",
          animation: `swipe ${speed}ms linear infinite backwards`,
          height: "100%",
        }}
      >
        {footerItems.map((item) => (
          <ScrollingFooterItem
            key={item.id}
            actionId={item.id}
            hint={item.hint}
          />
        ))}
      </section>
    </Stack>
  )
}

export { ScrollingFooter }
