import { Stack } from "@mui/material"
import actionsList from "../../service/actionsList"
import Text from "./Text"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import { relativeHeight, relativeWidth } from "../../service/utilities"

const ScrollingFooterItem = ({
  actionId,
  hint,
}: {
  actionId: string
  hint: string
}) => {
  const { lang } = useContext(MainContext)

  // current action
  const action = actionsList.find((item) => item.id === actionId)!

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        height: "100%",
        gap: relativeWidth(16),
        backgroundColor: action.category.backColor,
        paddingInline: relativeWidth(32),
        position: "relative",
        paddingBottom: relativeHeight(4),
      }}
    >
      <img src={action.image} style={{ width: relativeWidth(60) }} />
      <Text
        fontSize={relativeWidth(22)}
        fontWeight={700}
        style={{ whiteSpace: "nowrap" }}
      >
        {action.document.items.find((item) => item.lang === lang!.id)
          ? action.document.items.find((item) => item.lang === lang!.id)!.title
          : action.document.items.find((item) => item.default)!.title}
        :{" "}
      </Text>
      <Text
        fontSize={relativeWidth(22)}
        fontWeight={500}
        style={{ whiteSpace: "nowrap", marginLeft: `-${relativeWidth(8)}` }}
      >
        {hint}
      </Text>
      <div
        style={{
          width: "100%",
          height: relativeHeight(6),
          position: "absolute",
          bottom: 0,
          left: 0,
          backgroundColor: action.category.foreColor,
        }}
      />
    </Stack>
  )
}

export default ScrollingFooterItem
