import { CircularProgress } from "@mui/material"
import {
  relativeHeight,
  relativeWidth,
  roundWithDecimalPlaces,
} from "../../service/utilities"
import Text from "../global/Text"
import { useContext, useEffect, useMemo, useState } from "react"
import colors, { chartDirection } from "../../service/constants"
import ChartItem from "../../models/chartItem"
import { MainContext } from "../../controllers/main"

const BubbleChartItem = ({
  data,
  item,
  index,
  yValues,
}: {
  data: ChartItem[]
  item: ChartItem
  index: number
  yValues: number
}) => {
  const { hideEmployeeFootprint } = useContext(MainContext)

  // factors
  const widthFactor = 115 // higher is bigger
  const fontFactor = 3.2 // lower is bigger
  const minWidth = 28 // min width for bubbles
  const maxWidth = 115 // max width for bubbles

  // width (diameter) of the current item
  const width = (item.chartWeight * widthFactor) / 15
  const insideWidth = (item.chartWeight * (widthFactor - 12)) / 15
  const outsideWidth = (item.chartWeight * (widthFactor + 20)) / 15

  // font size of the current item
  const fontSize = width / fontFactor

  // distance of the current item from bottom
  const bottomMemo = useMemo(() => {
    return (
      (item.chartWeight *
        (yValues === 4
          ? 262
          : yValues === 5
          ? 196.5
          : yValues === 6
          ? 157.2
          : yValues === 7
          ? 131
          : yValues === 8
          ? 112.29
          : yValues === 9
          ? 98.25
          : yValues === 10
          ? 87.1
          : yValues === 11
          ? 78.6
          : yValues === 12
          ? 71.5
          : 65.55)) /
      5
    )
  }, [yValues, item])

  // animation
  const [animate, setAnimate] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true)
    }, 10)
  }, [])

  // background color selected randomly
  const backgroundColor = useMemo(() => {
    const availableColors = ["#FCEFCB", "#F7CDE0", "#F8DCC5", "#CCEEE7"]
    return availableColors[Math.floor(Math.random() * 4)]
  }, [])

  return (
    <div
      style={{
        width: relativeWidth(4),
        height: relativeWidth(4),
        borderRadius: "50%",
        position: "absolute",
        zIndex: 5,
        left:
          chartDirection === "left"
            ? relativeWidth(
                80 +
                  data
                    .slice(0, index)
                    .reduce(
                      (partialSum, a) =>
                        partialSum +
                        ((a.chartWeight > 15
                          ? 15
                          : a.chartWeight < 4
                          ? 4
                          : a.chartWeight) *
                          widthFactor) /
                          12,
                      0
                    ) +
                  (width >= maxWidth
                    ? maxWidth / 2
                    : width <= minWidth
                    ? minWidth / 2
                    : width / 2) +
                  20 * index
              )
            : undefined,
        right:
          chartDirection === "right"
            ? relativeWidth(
                80 +
                  data
                    .slice(0, index)
                    .reduce(
                      (partialSum, a) =>
                        partialSum +
                        ((a.chartWeight > 15
                          ? 15
                          : a.chartWeight < 4
                          ? 4
                          : a.chartWeight) *
                          widthFactor) /
                          12,
                      0
                    ) +
                  (width >= maxWidth
                    ? maxWidth / 2
                    : width <= minWidth
                    ? minWidth / 2
                    : width / 2) +
                  20 * index
              )
            : undefined,
        bottom: relativeHeight(42 + bottomMemo),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        opacity: animate ? 1 : 0,
        transition: `opacity 900ms, ${chartDirection} 250ms, bottom 400ms`,
      }}
    >
      <CircularProgress
        variant="determinate"
        value={animate ? item.employee_percent : 0}
        thickness={3}
        style={{
          width: relativeWidth(outsideWidth),
          minWidth: relativeWidth(minWidth + 5),
          maxWidth: relativeWidth(maxWidth + 18),
          height: relativeWidth(outsideWidth),
          minHeight: relativeWidth(minWidth + 5),
          maxHeight: relativeWidth(maxWidth + 18),
          position: "absolute",
          strokeLinecap: "round",
          backgroundColor: backgroundColor,
          borderRadius: "50%",
        }}
      />
      {!hideEmployeeFootprint && (
        <div
          style={{
            backgroundColor: "white",
            width: relativeWidth(width),
            minWidth: relativeWidth(minWidth),
            maxWidth: relativeWidth(maxWidth),
            height: relativeWidth(width),
            minHeight: relativeWidth(minWidth),
            maxHeight: relativeWidth(maxWidth),
            borderRadius: "50%",
            position: "absolute",
          }}
        />
      )}
      <div
        style={{
          backgroundColor: backgroundColor,
          width: relativeWidth(insideWidth),
          minWidth: relativeWidth(minWidth - 4),
          maxWidth: relativeWidth(maxWidth - 12),
          height: relativeWidth(insideWidth),
          minHeight: relativeWidth(minWidth - 4),
          maxHeight: relativeWidth(maxWidth - 12),
          borderRadius: "50%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          fontSize={relativeWidth(
            fontSize >= 35.9 ? 35.9 : fontSize >= 10 ? fontSize : 10
          )}
          fontWeight={600}
        >
          {roundWithDecimalPlaces(item.footprint, 1)
            .toString()
            .replace(".", ",")}
        </Text>
      </div>
      <Text
        fontSize={relativeWidth(16)}
        fontWeight={500}
        style={{
          maxWidth: relativeWidth(85),
          whiteSpace: "nowrap",
          position: "absolute",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          top: relativeHeight(
            outsideWidth > maxWidth + 18
              ? maxWidth / 2 + 18
              : outsideWidth < minWidth + 5
              ? minWidth
              : outsideWidth / 2 + 8
          ),
        }}
      >
        {item.first_name}
      </Text>
    </div>
  )
}

export default BubbleChartItem
