import { CSSProperties, ReactNode } from "react"
import colors from "../../service/constants"
import { relativeWidth } from "../../service/utilities"

const Text = ({
  children,
  color = colors.text,
  fontSize = relativeWidth(22),
  fontWeight = 400,
  lineHeight,
  style,
}: {
  children: ReactNode
  color?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  style?: CSSProperties
}) => {
  return (
    <p
      style={{
        color: color,
        fontSize: fontSize,
        fontWeight: fontWeight,
        lineHeight: lineHeight,
        ...style,
      }}
    >
      {children}
    </p>
  )
}

export default Text
