const baseWidth = 1920
const baseHeight = 1080
const chartDirection: "left" | "right" = "right"

const colors = {
  primary: "#0074E4",
  background: "#E4F0FE",
  text: "#001E40",
  footprint: {
    home: "#F2C65D",
    homeBackground: "#FCEFCB",
    shopping: "#EC609B",
    shoppingBackground: "#F7CDE0",
    transport: "#EA8E47",
    transportBackground: "#F8DCC5",
    food: "#69C6AF",
    foodBackground: "#CCEEE7",
  },
}

export default colors
export { baseWidth, baseHeight, chartDirection }
