import { useContext, useEffect, useRef, useState } from "react"
import ChartCard from "./components/chart/ChartCard"
import InfoCard from "./components/info/InfoCard"
import LeaderboardCard from "./components/leaderboard/LeaderboardCard"
import { relativeHeight, relativeWidth } from "./service/utilities"
import { MainContext } from "./controllers/main"
import { ScrollingFooter } from "./components/global/ScrollingFooter"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { AnimatePresence, motion } from "framer-motion"
import { Button, Stack, ToggleButton } from "@mui/material"
import Text from "./components/global/Text"
import i18n from "i18next"

const App = () => {
  const fullScreenHandle = useFullScreenHandle()
  const {
    loading,
    showSettingsPage,
    availableEvents,
    availableLangs,
    event,
    setEvent,
    lang,
    setLang,
    showFooter,
    setShowFooter,
    showScreensavers,
    setShowScreensavers,
    connect,
  } = useContext(MainContext)

  // rotating images on top of everything
  const currentImage = useRef<number>(0)
  const [showImage, setShowImage] = useState<boolean>(false)

  // setting an interval to make images rotate on top of the screen
  useEffect(() => {
    if (!loading && !showSettingsPage && event && lang && showScreensavers) {
      event.screensavers[1] = event.screensavers[1].replace("{lang}", lang.id)

      setInterval(() => {
        setShowImage(true)
        setTimeout(() => {
          setShowImage(false)
          setTimeout(() => {
            if (currentImage.current < event.screensavers.length - 1) {
              currentImage.current += 1
            } else {
              currentImage.current = 0
            }
          }, 1000)
        }, 5000)
      }, 65000)
    }
  }, [loading, showSettingsPage])

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className="loader" />
      </div>
    )
  }

  if (showSettingsPage) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{ width: "100%", height: "100vh" }}
      >
        <Text
          fontSize={relativeWidth(36)}
          fontWeight={600}
          style={{ textAlign: "center" }}
        >
          Scegli con quali impostazioni avviare la
          <br />
          Carbon Footprint Station
        </Text>
        <Text
          fontSize={relativeWidth(28)}
          fontWeight={500}
          style={{ textAlign: "center", marginTop: relativeHeight(64) }}
        >
          Lingua:
        </Text>
        <Stack
          direction="row"
          style={{ gap: relativeWidth(16), marginTop: relativeHeight(24) }}
        >
          {availableLangs.map((item) => (
            <ToggleButton
              color="primary"
              key={item.id}
              value="check"
              selected={lang && item.id === lang.id ? true : false}
              onChange={() => {
                setLang(item)
                i18n.changeLanguage(item.id)
              }}
            >
              {item.label}
            </ToggleButton>
          ))}
        </Stack>
        <Text
          fontSize={relativeWidth(28)}
          fontWeight={500}
          style={{ textAlign: "center", marginTop: relativeHeight(64) }}
        >
          Evento:
        </Text>
        <Stack
          direction="row"
          style={{ gap: relativeWidth(16), marginTop: relativeHeight(24) }}
        >
          {availableEvents.map((item) => (
            <ToggleButton
              color="primary"
              key={item.id}
              value="check"
              selected={event && item.id === event.id ? true : false}
              onChange={() => {
                setEvent(item)
              }}
            >
              {item.label}
            </ToggleButton>
          ))}
        </Stack>
        <Stack
          direction="row"
          style={{ marginTop: relativeHeight(64), gap: relativeWidth(64) }}
        >
          <Stack alignItems="center">
            <Text
              fontSize={relativeWidth(28)}
              fontWeight={500}
              style={{ textAlign: "center" }}
            >
              Footer:
            </Text>
            <Stack
              direction="row"
              style={{ gap: relativeWidth(16), marginTop: relativeHeight(24) }}
            >
              <ToggleButton
                color="primary"
                value="check"
                selected={showFooter === true}
                onChange={() => {
                  setShowFooter(true)
                }}
                style={{ width: 50 }}
              >
                Sì
              </ToggleButton>
              <ToggleButton
                color="primary"
                value="check"
                selected={showFooter === false}
                onChange={() => {
                  setShowFooter(false)
                }}
                style={{ width: 50 }}
              >
                No
              </ToggleButton>
            </Stack>
          </Stack>
          <Stack alignItems="center">
            <Text
              fontSize={relativeWidth(28)}
              fontWeight={500}
              style={{ textAlign: "center" }}
            >
              Screensavers:
            </Text>
            <Stack
              direction="row"
              style={{ gap: relativeWidth(16), marginTop: relativeHeight(24) }}
            >
              <ToggleButton
                color="primary"
                value="check"
                selected={showScreensavers === true}
                onChange={() => {
                  setShowScreensavers(true)
                }}
                style={{ width: 50 }}
              >
                Sì
              </ToggleButton>
              <ToggleButton
                color="primary"
                value="check"
                selected={showScreensavers === false}
                onChange={() => {
                  setShowScreensavers(false)
                }}
                style={{ width: 50 }}
              >
                No
              </ToggleButton>
            </Stack>
          </Stack>
        </Stack>
        <Button
          disabled={!event || !lang}
          color="primary"
          variant="contained"
          style={{
            width: relativeWidth(200),
            height: relativeHeight(66),
            marginTop: relativeHeight(64),
          }}
          onClick={connect}
        >
          Avvia
        </Button>
      </Stack>
    )
  }

  return (
    <FullScreen handle={fullScreenHandle}>
      <AnimatePresence>
        {showImage && (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.8 }}
            src={event?.screensavers[currentImage.current]}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "fixed",
              top: 0,
              backgroundColor: "#FFFFFF",
              zIndex: 100,
            }}
          />
        )}
      </AnimatePresence>
      <div
        onDoubleClick={() => {
          if (fullScreenHandle.active) {
            fullScreenHandle.exit()
          } else {
            fullScreenHandle.enter()
          }
        }}
        style={{
          width: "100%",
          height: "100vh",
          paddingTop: showFooter ? relativeHeight(20) : relativeHeight(48),
          paddingBottom: showFooter ? relativeHeight(76) : relativeHeight(48),
          paddingInline: relativeWidth(45),
          display: "flex",
          flexDirection: "row",
          gap: relativeWidth(45),
          overflowY: "visible",
          backgroundColor: "#FFFFFF",
        }}
      >
        <InfoCard />
        <LeaderboardCard />
        <ChartCard />
        {showFooter && <ScrollingFooter />}
      </div>
    </FullScreen>
  )
}

export default App
