import { relativeHeight, relativeWidth } from "../../service/utilities"
import awLogo from "../../assets/images/aw-logo.svg"
import partnerLogo from "../../assets/images/partner-logo.png"
import Text from "../global/Text"
import colors from "../../service/constants"
import { useTranslation } from "react-i18next"
import qrImage from "../../assets/images/qr.png"
import { Stack } from "@mui/material"

const InfoCard = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        width: relativeWidth(287),
        minWidth: relativeWidth(287),
        height: "100%",
        borderRadius: relativeWidth(20),
        display: "flex",
        flexDirection: "column",
        position: "relative",
        paddingTop: relativeHeight(20),
      }}
    >
      <Stack gap={3}>
        <img
          src={awLogo}
          style={{
            width: relativeWidth(234),
          }}
        />
        <img
          src={partnerLogo}
          style={{
            width: relativeWidth(260),
          }}
        />
      </Stack>
      <Text
        fontSize={relativeWidth(26)}
        lineHeight={relativeHeight(36)}
        fontWeight={700}
        style={{ marginTop: relativeHeight(65) }}
        color={colors.primary}
      >
        {t("title")}
      </Text>
      <Text
        fontSize={relativeWidth(26)}
        fontWeight={700}
        color={colors.primary}
        lineHeight={relativeHeight(26)}
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: relativeHeight(65),
        }}
      >
        {t("Scan me!")}
      </Text>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={qrImage}
          style={{ width: "85%", marginTop: relativeHeight(10) }}
        />
      </div>
      {/* <Text
        fontSize={relativeWidth(22)}
        fontWeight={700}
        color={colors.primary}
        lineHeight={relativeHeight(24)}
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: relativeHeight(10),
        }}
      >
        {t("calculate_your_carbon_footprint")}
      </Text> */}
    </div>
  )
}

export default InfoCard
