import { useContext, useMemo } from "react"
import BubbleChartItem from "./BubbleChartItem"
import ChartItem from "../../models/chartItem"
import { MainContext } from "../../controllers/main"

const BubbleChart = ({ yValues }: { yValues: number }) => {
  const { data } = useContext(MainContext)

  // reduce data to make the chart items not exceed the chart width
  const dataMemo = useMemo(() => {
    const dataToReturn: ChartItem[] = JSON.parse(
      JSON.stringify(data.lastItems.slice(0))
    )

    dataToReturn.reduce((tot, curr, index, arr) => {
      if (
        tot +
          (curr.chartWeight > 15
            ? 15
            : curr.chartWeight < 4
            ? 4
            : curr.chartWeight) >
        64
      ) {
        arr.splice(index).reverse()
      }

      if (curr.chartWeight > 15) {
        return (tot += 15)
      } else if (curr.chartWeight < 4) {
        return (tot += 4)
      } else {
        return (tot += curr.chartWeight)
      }
    }, 0)

    return dataToReturn
  }, [data])

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      {dataMemo.map((item, index) => (
        <BubbleChartItem
          key={index}
          data={dataMemo}
          item={item}
          index={index}
          yValues={yValues}
        />
      ))}
    </div>
  )
}

export default BubbleChart
