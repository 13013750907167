import { baseHeight, baseWidth } from "./constants"

// calculate relative width (vw)
export const relativeWidth = (width: number) => {
  return (100 * width) / baseWidth + "vw"
}

// calculate relative height (vh)
export const relativeHeight = (height: number) => {
  return (100 * height) / baseHeight + "vh"
}

// round a number to 2 decimal places
export const roundTo2Decimals = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

// round number to 1 decimal places if necessary
export const roundWithDecimalPlaces = (num: number, decimalPlaces: number) => {
  const value = Math.pow(10, decimalPlaces)

  return Math.round((num + Number.EPSILON) * value) / value
}
