import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { MainController } from "./controllers/main"
import "./service/i18n/i18n"
import { BrowserRouter } from "react-router-dom"
import { Shadows, ThemeProvider, createTheme } from "@mui/material"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
          primary: {
            main: "#3172DC",
          },
          secondary: {
            main: "#3172DC",
          },
        },
        shadows: Array(25).fill("none") as Shadows,
      })}
    >
      <MainController>
        <App />
      </MainController>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
