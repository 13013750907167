const footerItems = [
  {
    id: "0b290073-6b58-47f4-a78c-466687eff6bd",
    hint: "il trasporto alimentare rappresenta il 19% delle emissioni totali del sistema alimentare: limitiamolo!",
  },
  {
    id: "063f5fa9-0172-480e-bd8e-c5e251030936",
    hint: "sapevi che anche se sono spenti gli apparecchi collegati consumano circa il 7% dell’energia normale?",
  },
  {
    id: "2b839bfb-5e7f-4487-90ed-fdfb6273e2f5",
    hint: "mediamente sprechiamo circa il 17% del cibo a disposizione, possiamo ridurre questi sprechi programmando i nostri pasti.",
  },
  {
    id: "5af51368-a16f-46fd-9f23-b885fbacc461",
    hint: "un PC consuma circa 2 Watt di energia in modalità sleep! Spegnerlo quando non lo utilizzi è un’abitudine semplice e molto utile al Pianeta!",
  },
  {
    id: "bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c",
    hint: "scegliendo i mezzi al posto della macchina, possiamo risparmiare mediamente 1.05kg di emissioni di CO2e al giorno!",
  },
  {
    id: "c02c13db-feba-4c03-b302-38b9e50cea5c",
    hint: "sapevi che un capo di abbigliamento medio emette circa 16kg di CO2e? Vintage è cool per te e per il Pianeta!",
  },
  {
    id: "77f0fc2f-7ef9-46b6-8c61-121e708a444f",
    hint: "quando è possibile, migliora la nostra salute e ci fa risparmiare circa 0,34 kg di CO2e.",
  },
  {
    id: "951ee6b6-94a3-46b0-87ed-aaac491f9b35",
    hint: "per produrre un cellulare occorrono più di 12 litri di acqua e 55kg di CO2e... Quando possibile, ricordiamoci che le cose si possono riparare, prima di essere buttate e sostituite.",
  },
  {
    id: "79dc2a88-68db-4e0e-8f25-9a5148de5747",
    hint: "riordinando l’armadio capita sempre di trovare vestiti che non usiamo più: donandoli faremo felice qualcuno.",
  },
  {
    id: "ff50cfa0-bd4f-4d95-8907-49fcb80d675b",
    hint: "è importante ridurre gli sprechi, anche in viaggio! Non rinunciare al tuo bagnoschiuma preferito: travasalo in una bottiglietta da viaggio...",
  },
  {
    id: "e5fe5392-ac78-43a4-8ee4-9ac8639795d2",
    hint: "riciclando la plastica si stima un risparmio di circa 0,316 kg di CO2e al giorno!",
  },
  {
    id: "66ba9e13-c4e5-4bbb-958a-033fa1e38f79",
    hint: "è esattamente ciò che state facendo, che aspettate a registrare l’azione sull’app AWorld?",
  },
  {
    id: "7386759a-451f-456d-a723-fd8c053f8cbd",
    hint: "sapevi che saltando una notte il cambio di biancheria in hotel potresti risparmiare più di 3kg di CO2e?",
  },
  {
    id: "946bd5e6-4a8d-4946-977b-fd22e990749f",
    hint: "le tote bags sono cool! Usarle per fare la spesa al posto della busta di plastica ti fa risparmiare acqua e CO2e!",
  },
  {
    id: "fb55c6da-1a21-4cab-bb78-11ce6d02521a",
    hint: "che bello circondarsi di verde! Lo è per te e per il Pianeta",
  },
  {
    id: "57b69752-c85f-49b2-bf66-10533eb2c015",
    hint: "sapevi che nei rubinetti degli USA scorrono circa 8 litri ogni minuto? Interrompi il flusso d’acqua per qualche secondo mentre ti lavi i denti!",
  },
  {
    id: "a665c1c9-e46e-4332-b8a2-b0544ee43f9f",
    hint: "la gratitudine si impara ed è uno splendido esercizio... Perché non cominciare oggi?",
  },
  {
    id: "a274a92c-ab03-4424-920e-3e4af3e4acba",
    hint: "accorciando la tua doccia puoi risparmiare più di 47 litri di acqua!",
  },
]

export default footerItems
