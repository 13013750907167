import { useContext } from "react"
import { MainContext } from "../../controllers/main"
import colors from "../../service/constants"
import {
  relativeHeight,
  relativeWidth,
  roundWithDecimalPlaces,
} from "../../service/utilities"
import Text from "../global/Text"

const LeaderboardTop3 = () => {
  const { data } = useContext(MainContext)

  return (
    <div
      style={{
        width: "100%",
        height: relativeHeight(171),
        marginTop: relativeHeight(36),
        display: "flex",
        flexDirection: "row",
      }}
    >
      {data.ranking[1] ? (
        <Dot
          place={2}
          name={data.ranking[1].first_name}
          impact={data.ranking[1].footprint}
        />
      ) : (
        <EmptyDot place={2} />
      )}
      {data.ranking[0] ? (
        <Dot
          place={1}
          name={data.ranking[0].first_name}
          impact={data.ranking[0].footprint}
        />
      ) : (
        <EmptyDot place={1} />
      )}
      {data.ranking[2] ? (
        <Dot
          place={3}
          name={data.ranking[2].first_name}
          impact={data.ranking[2].footprint}
        />
      ) : (
        <EmptyDot place={3} />
      )}
    </div>
  )
}

const Dot = ({
  place,
  name,
  impact,
}: {
  place: 1 | 2 | 3
  name: string
  impact: number
}) => {
  return (
    <div
      style={{
        width: "33.33%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: place === 1 ? "flex-start" : "flex-end",
      }}
    >
      <div
        style={{
          width: relativeWidth(57),
          height: relativeWidth(57),
          borderRadius: "100vw",
          backgroundColor: colors.primary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          fontSize={relativeWidth(24)}
          fontWeight={700}
          color="white"
          style={{
            marginLeft: relativeWidth(4),
            marginBottom: relativeHeight(2),
          }}
        >
          {place}°
        </Text>
      </div>
      <Text
        fontWeight={700}
        style={{
          marginTop: relativeHeight(6),
          maxWidth: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </Text>
      <Text
        fontSize={relativeWidth(20)}
        fontWeight={500}
        style={{ marginTop: relativeHeight(1) }}
      >
        {roundWithDecimalPlaces(impact, 1).toString().replace(".", ",")} kg
      </Text>
    </div>
  )
}

const EmptyDot = ({ place }: { place: 1 | 2 | 3 }) => {
  return (
    <div
      style={{
        width: "33.33%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: place === 1 ? "flex-start" : "flex-end",
      }}
    >
      <div
        style={{
          width: relativeWidth(57),
          height: relativeWidth(57),
          borderRadius: "100vw",
          backgroundColor: colors.primary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          fontSize={relativeWidth(24)}
          fontWeight={700}
          color="white"
          style={{
            marginLeft: relativeWidth(4),
            marginBottom: relativeHeight(2),
          }}
        >
          {place}°
        </Text>
      </div>
      <div
        style={{ height: relativeHeight(25.5), marginTop: relativeHeight(6) }}
      />
      <div
        style={{ height: relativeHeight(23.5), marginTop: relativeHeight(1) }}
      />
    </div>
  )
}

export default LeaderboardTop3
