import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import LeaderboardItem from "../models/leaderboardItem"
import ChartItem from "../models/chartItem"
import { roundTo2Decimals } from "../service/utilities"
import Event from "../models/event"
import Language from "../models/language"

interface MainContextInterface {
  loading: boolean
  data: {
    ranking: LeaderboardItem[]
    lastItems: ChartItem[]
  }
  hideEmployeeFootprint: boolean
  showSettingsPage: boolean
  availableEvents: Event[]
  availableLangs: Language[]
  event: Event | null
  setEvent: Dispatch<SetStateAction<Event | null>>
  lang: Language | null
  setLang: Dispatch<SetStateAction<Language | null>>
  showFooter: boolean
  setShowFooter: Dispatch<SetStateAction<boolean>>
  showScreensavers: boolean
  setShowScreensavers: Dispatch<SetStateAction<boolean>>
  connect: () => void
  chartYStepSize: number
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  data: { ranking: [], lastItems: [] },
  hideEmployeeFootprint: false,
  showSettingsPage: true,
  availableEvents: [],
  availableLangs: [],
  event: null,
  setEvent: () => {},
  lang: { id: "en", label: "Inglese" },
  setLang: () => {},
  showFooter: true,
  setShowFooter: () => {},
  showScreensavers: true,
  setShowScreensavers: () => {},
  connect: () => {},
  chartYStepSize: 5,
})

const MainController = ({ children }: { children: ReactNode }) => {
  // states
  const [loading, setLoading] = useState<boolean>(true) // loading state
  const [data, setData] = useState<{
    ranking: LeaderboardItem[]
    lastItems: ChartItem[]
  }>({ ranking: [], lastItems: [] }) // data from websocket
  const [hideEmployeeFootprint, setHideEmployeeFootprint] =
    useState<boolean>(false) // if should hide employee footprint in bubbles and legend or not
  const [showSettingsPage, setShowSettingsPage] = useState<boolean>(true) // if should show settings page or not
  const [chartYStepSize, setChartYStepSize] = useState<number>(5) // step size of the chart

  // settings states
  const [availableEvents, setAvailableEvents] = useState<Event[]>([]) // list of all available events
  const [availableLangs, setAvailableLangs] = useState<Language[]>([]) // list of all available events
  const [event, setEvent] = useState<Event | null>(null) // current event
  const [lang, setLang] = useState<Language | null>(null) // station language
  const [showFooter, setShowFooter] = useState<boolean>(false) // if should show scrolling footer or not
  const [showScreensavers, setShowScreensavers] = useState<boolean>(false) // if should show screensavers or not

  // parse incoming data
  const parseData = (data: {
    ranking: LeaderboardItem[]
    lastItems: ChartItem[]
  }) => {
    const newData: {
      ranking: LeaderboardItem[]
      lastItems: ChartItem[]
    } = JSON.parse(JSON.stringify(data))

    // decide chart y step size (multiple of 50) (minimum 5)
    const maxValue = Math.max(
      ...newData.lastItems.map((item) => item.footprint)
    )
    let stepSize = Math.round(((maxValue / 1000) * 200) / 10) * 10
    if (!stepSize) {
      stepSize = 5
    }
    setChartYStepSize(stepSize)

    newData.ranking.forEach((item) => {
      // convert footprint to tons and round it
      item.footprint = roundTo2Decimals(item.footprint)
    })
    newData.lastItems.forEach((item) => {
      // assing to every value a weight for chart
      item.chartWeight = roundTo2Decimals((item.footprint * 5) / stepSize)

      // find highest footprint category
      let objectToSearchMaxIn: {
        footprint_shopping?: number
        footprint_food?: number
        footprint_home?: number
        footprint_transport?: number
      } = {}
      objectToSearchMaxIn.footprint_shopping = item.footprint_shopping
      objectToSearchMaxIn.footprint_food = item.footprint_food
      objectToSearchMaxIn.footprint_home = item.footprint_home
      objectToSearchMaxIn.footprint_transport = item.footprint_transport
      let highestCategory = Object.keys(objectToSearchMaxIn).reduce((a, b) =>
        (objectToSearchMaxIn as any)[a] > (objectToSearchMaxIn as any)[b]
          ? a
          : b
      )
      item.highest_category = highestCategory.slice(
        highestCategory.indexOf("_") + 1
      )

      // find employee percent on total footprint
      if (item.footprint_employee) {
        item.employee_percent = Math.round(
          (item.footprint_employee / item.footprint) * 100
        )
      } else {
        item.employee_percent = 0
      }
    })

    return newData
  }

  // open connection with websocket
  const connect = () => {
    setLoading(true)
    setShowSettingsPage(false)

    // open connection
    const webSocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL!)

    webSocket.onopen = (e) => {
      console.log(`event name ⏵ ${event!.id}`)
      console.log(`websocket connection ⏵ ${e.type}`)

      // send message to websocket to retrieve initial data
      const timezoneOffset = new Date().getTimezoneOffset()
      const messageToSend = {
        action: "sendmessage",
        event_name: event!.id,
        timezone:
          timezoneOffset === 0
            ? "0"
            : (timezoneOffset / 60) * -1 > 0
            ? "+" + ((timezoneOffset / 60) * -1).toString()
            : ((timezoneOffset / 60) * -1).toString(),
      }
      webSocket.send(JSON.stringify(messageToSend))
      console.log("message sent to websocket", messageToSend)
    }

    webSocket.onmessage = (e) => {
      const data = JSON.parse(e.data)
      const parsedData = parseData(data)
      console.log("data", parsedData)

      setData(parsedData)

      // if footprint employee is not present, hide it in chart
      if (
        parsedData.lastItems.length &&
        !parsedData.lastItems.filter((item: any) => item.footprint_employee)
          .length
      ) {
        setHideEmployeeFootprint(true)
      }

      setLoading(false)
    }

    webSocket.onclose = (e) => {
      console.log(`websocket connection ⏵ ${e.type}`)
      console.log(`trying to reconnect in 5 seconds`)

      setTimeout(connect, 5000)
    }
  }

  // get config json from s3 bucket
  const getConfig = async () => {
    const data = await (
      await fetch(
        "https://dist.aworld.click/cf-station-mobility/config/config.json"
      )
    ).json()

    console.log("config", data)
    data.availableEvents.find((event: Event) => event.label === "KEY").id =
      "key_energy_2024"
    setAvailableEvents(data.availableEvents)
    setAvailableLangs(data.availableLanguages)

    setLoading(false)
  }

  // get station config
  useEffect(() => {
    getConfig()
  }, [])

  return (
    <MainContext.Provider
      value={{
        loading,
        data,
        hideEmployeeFootprint,
        showSettingsPage,
        availableEvents,
        availableLangs,
        event,
        setEvent,
        lang,
        setLang,
        showFooter,
        setShowFooter,
        showScreensavers,
        setShowScreensavers,
        connect,
        chartYStepSize,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export { MainController, MainContext }
