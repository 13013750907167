import { useContext, useEffect, useState } from "react"
import { relativeHeight, relativeWidth } from "../../service/utilities"
import Text from "../global/Text"
import BubbleChart from "./BubbleChart"
import { MainContext } from "../../controllers/main"
import ChartItem from "../../models/chartItem"
import { useTranslation } from "react-i18next"

const ChartCard = () => {
  const { t } = useTranslation()
  const { data, chartYStepSize } = useContext(MainContext)

  // number of values in chart y axis
  const [yValues, setYValues] = useState<number>(0)

  useEffect(() => {
    if (data.lastItems.length) {
      const dataCopy: ChartItem[] = JSON.parse(JSON.stringify(data.lastItems))

      dataCopy.reduce((tot, curr, index, arr) => {
        if (
          tot +
            (curr.chartWeight > 15
              ? 15
              : curr.chartWeight < 4
              ? 4
              : curr.chartWeight) >
          64
        ) {
          arr.splice(index).reverse()
        }

        if (curr.chartWeight > 15) {
          return (tot += 15)
        } else if (curr.chartWeight < 4) {
          return (tot += 4)
        } else {
          return (tot += curr.chartWeight)
        }
      }, 0)

      const max = Math.max(...dataCopy.map((item) => item.chartWeight))

      if (max > 0) {
        setYValues(Math.ceil(max / 5) + 1 > 5 ? Math.ceil(max / 5) + 1 : 5)
      } else {
        setYValues(5)
      }
    } else {
      setYValues(5)
    }
  }, [data])

  return !yValues ? null : (
    <div
      style={{
        width: relativeWidth(1053),
        minWidth: relativeWidth(1053),
        height: "100%",
        borderRadius: relativeWidth(20),
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
        paddingInline: relativeWidth(28),
        paddingTop: relativeHeight(88),
        paddingBottom: relativeHeight(26),
        display: "flex",
        flexDirection: "row",
        position: "relative",
      }}
    >
      <div
        style={{
          width: relativeWidth(112),
          height: "100%",
          display: "flex",
          flexDirection: "column",
          paddingTop: relativeHeight(28),
          paddingBottom: relativeHeight(32),
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {Array.from(Array(yValues).keys())
          .reverse()
          .map((item) => (
            <Text key={item} fontWeight={700}>
              {item * chartYStepSize} kg
            </Text>
          ))}
      </div>
      <div
        style={{
          width: relativeWidth(7),
          height: "100%",
          background:
            "linear-gradient(0deg, #8FD0B5 0%, #FFDCB5 51.04%, #DD7D70 100%)",
          borderRadius: "100vw",
          marginLeft: relativeWidth(24),
        }}
      />
      <BubbleChart yValues={yValues} />
      <Text
        fontSize={relativeWidth(14)}
        fontWeight={700}
        style={{
          position: "absolute",
          bottom: relativeHeight(26),
          left: relativeWidth(67),
        }}
      >
        {t("kg_co2e")}
      </Text>
    </div>
  )
}

export default ChartCard
