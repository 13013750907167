const actionsList = [
  {
    id: "bfdeb06f-4c07-4711-815c-6aab97ce1c63",
    createdAt: "2023-08-21T17:44:31.758Z",
    updatedAt: "2023-10-02T14:27:17.368Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/08/feed-the-culture-6255979bcdaa.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#bfdeb06f-4c07-4711-815c-6aab97ce1c63#stage#PUBLISHED",
          title: "Alimentare la cultura",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#bfdeb06f-4c07-4711-815c-6aab97ce1c63#stage#PUBLISHED",
          title: "Nourrir sa culture",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#bfdeb06f-4c07-4711-815c-6aab97ce1c63#stage#PUBLISHED",
          title: "Alimentar la cultura",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#bfdeb06f-4c07-4711-815c-6aab97ce1c63#stage#PUBLISHED",
          title: "Feed the culture",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#bfdeb06f-4c07-4711-815c-6aab97ce1c63#stage#PUBLISHED",
          title: "Kulturpflege",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "f12efbf8-bf88-4a44-996a-b9aa8da508fa",
    createdAt: "2023-08-21T17:37:16.441Z",
    updatedAt: "2023-10-02T14:19:24.367Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/08/advocate-for-social-issues-849a6cb67330.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#f12efbf8-bf88-4a44-996a-b9aa8da508fa#stage#PUBLISHED",
          title: "Agisci per cause sociali",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#f12efbf8-bf88-4a44-996a-b9aa8da508fa#stage#PUBLISHED",
          title: "Agissez pour les causes sociales",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#f12efbf8-bf88-4a44-996a-b9aa8da508fa#stage#PUBLISHED",
          title: "Actúa por las causas sociales",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#f12efbf8-bf88-4a44-996a-b9aa8da508fa#stage#PUBLISHED",
          title: "Advocate for social issues",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#f12efbf8-bf88-4a44-996a-b9aa8da508fa#stage#PUBLISHED",
          title: "Handeln sie für soziale zwecke",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "ed237b90-9888-40fa-a103-3ad875196766",
    createdAt: "2023-08-21T17:49:01.764Z",
    updatedAt: "2023-10-02T14:14:46.947Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/08/support-financially-c231522191a1.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#ed237b90-9888-40fa-a103-3ad875196766#stage#PUBLISHED",
          title: "Supporta economicamente una causa",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#ed237b90-9888-40fa-a103-3ad875196766#stage#PUBLISHED",
          title: "Supportez financièrement une cause",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#ed237b90-9888-40fa-a103-3ad875196766#stage#PUBLISHED",
          title: "Apoyar económicamente una causa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#ed237b90-9888-40fa-a103-3ad875196766#stage#PUBLISHED",
          title: "Support Financially",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#ed237b90-9888-40fa-a103-3ad875196766#stage#PUBLISHED",
          title: "Unterstützen sie eine sache finanziell",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "e6caba75-4118-4c1f-acd0-9f05ef2cdfff",
    createdAt: "2023-08-21T17:40:41.277Z",
    updatedAt: "2023-10-02T14:13:55.609Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/08/stand-up-for-a-just-society-572b596466fc.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#e6caba75-4118-4c1f-acd0-9f05ef2cdfff#stage#PUBLISHED",
          title: "Lotta per una società più giusta",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#e6caba75-4118-4c1f-acd0-9f05ef2cdfff#stage#PUBLISHED",
          title: "Luttez pour une société plus juste",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#e6caba75-4118-4c1f-acd0-9f05ef2cdfff#stage#PUBLISHED",
          title: "Lucha por una sociedad más justa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#e6caba75-4118-4c1f-acd0-9f05ef2cdfff#stage#PUBLISHED",
          title: "Stand up for a just society",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#e6caba75-4118-4c1f-acd0-9f05ef2cdfff#stage#PUBLISHED",
          title: "Kämpfen sie für eine gerechtigere gesellschaft",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "6ca11dcf-5a4b-4dc6-b4aa-74eb98fd8283",
    createdAt: "2023-04-06T13:06:08.614Z",
    updatedAt: "2023-09-22T13:08:49.720Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-il-compost-bee856c4c414.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#6ca11dcf-5a4b-4dc6-b4aa-74eb98fd8283#stage#PUBLISHED",
          title: "Fai il compost",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#6ca11dcf-5a4b-4dc6-b4aa-74eb98fd8283#stage#PUBLISHED",
          title: "Faites votre compostage",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#6ca11dcf-5a4b-4dc6-b4aa-74eb98fd8283#stage#PUBLISHED",
          title: "Recicla el orgánico",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#6ca11dcf-5a4b-4dc6-b4aa-74eb98fd8283#stage#PUBLISHED",
          title: "Make the compost",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#6ca11dcf-5a4b-4dc6-b4aa-74eb98fd8283#stage#PUBLISHED",
          title: "Kompostieren sie",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.102,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "732d8ce0-e246-4ada-94a0-196cd0353cb6",
    createdAt: "2023-08-22T15:50:15.813Z",
    updatedAt: "2023-09-06T08:40:44.955Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/08/be-careful-with-cigarette-butts-c665476697eb.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#732d8ce0-e246-4ada-94a0-196cd0353cb6#stage#PUBLISHED",
          title: "Attenzione al mozzicone",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#732d8ce0-e246-4ada-94a0-196cd0353cb6#stage#PUBLISHED",
          title: "Attention au mégot",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "en",
          parentId: "#id#732d8ce0-e246-4ada-94a0-196cd0353cb6#stage#PUBLISHED",
          title: "Be careful with cigarette butts",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "es",
          parentId: "#id#732d8ce0-e246-4ada-94a0-196cd0353cb6#stage#PUBLISHED",
          title: "Cuidado con las colillas",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#732d8ce0-e246-4ada-94a0-196cd0353cb6#stage#PUBLISHED",
          title: "Achten Sie auf Ihre Zigarettenstummel",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "a9cdb38f-ad17-47e2-abf0-2b470a2bdb6d",
    createdAt: "2023-04-19T10:03:08.094Z",
    updatedAt: "2023-09-05T13:32:46.620Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/cuoci-con-il-fornello-spento-a4a4d59715db.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#a9cdb38f-ad17-47e2-abf0-2b470a2bdb6d#stage#PUBLISHED",
          title: "Termina la cottura con il fornello spento",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#a9cdb38f-ad17-47e2-abf0-2b470a2bdb6d#stage#PUBLISHED",
          title: "Éteignez la cuisinière avant la fin",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#a9cdb38f-ad17-47e2-abf0-2b470a2bdb6d#stage#PUBLISHED",
          title: "Aprovecha el calor residual al cocinar",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#a9cdb38f-ad17-47e2-abf0-2b470a2bdb6d#stage#PUBLISHED",
          title: "Cook with stove off / turn off stove first",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#a9cdb38f-ad17-47e2-abf0-2b470a2bdb6d#stage#PUBLISHED",
          title: "Kochen sie bei ausgeschaltetem herd",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.094,
          energySaving: 0.13,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "9b5c84de-d8bc-4d96-8448-2a6c453b6869",
    createdAt: "2023-04-05T15:29:21.486Z",
    updatedAt: "2023-08-22T07:50:52.770Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-il-bucato-a-30-gradi-ace334c72e13.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#9b5c84de-d8bc-4d96-8448-2a6c453b6869#stage#PUBLISHED",
          title: "使用 90°F 或 30°C 的水洗衣服",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#9b5c84de-d8bc-4d96-8448-2a6c453b6869#stage#PUBLISHED",
          title: "Fai il bucato a 30°C",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#9b5c84de-d8bc-4d96-8448-2a6c453b6869#stage#PUBLISHED",
          title: " Faites votre lessive a 30°",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#9b5c84de-d8bc-4d96-8448-2a6c453b6869#stage#PUBLISHED",
          title: " Lavar la ropa a 30°",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#9b5c84de-d8bc-4d96-8448-2a6c453b6869#stage#PUBLISHED",
          title: "Do the laundry at 60°F or 30°C",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#9b5c84de-d8bc-4d96-8448-2a6c453b6869#stage#PUBLISHED",
          title: "Waschen sie ihre wäsche bei 30°",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.77,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "f4c42ea3-0fcd-4bfc-8dab-10205f36d735",
    createdAt: "2023-04-19T09:55:23.016Z",
    updatedAt: "2023-08-21T17:25:50.818Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/apri-le-tende-o-gli-oscuranti-in-inverno-fd930f1f1093.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#f4c42ea3-0fcd-4bfc-8dab-10205f36d735#stage#PUBLISHED",
          title: "Apri le tende o gli oscuranti in inverno",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#f4c42ea3-0fcd-4bfc-8dab-10205f36d735#stage#PUBLISHED",
          title: "Ouvrez les rideaux et les stores l'hiver",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#f4c42ea3-0fcd-4bfc-8dab-10205f36d735#stage#PUBLISHED",
          title: "Abre cortinas y persianas en invierno ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#f4c42ea3-0fcd-4bfc-8dab-10205f36d735#stage#PUBLISHED",
          title: "Open the curtains or blinds in winter",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#f4c42ea3-0fcd-4bfc-8dab-10205f36d735#stage#PUBLISHED",
          title: "Öffnen sie im winter die vorhänge oder jalousien",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.56,
          energySaving: 3.71,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "54b9821b-7594-4a5d-98e3-f6b3e39a2e18",
    createdAt: "2023-04-06T12:55:48.312Z",
    updatedAt: "2023-07-24T15:38:37.961Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-un-sapone-solido-f2eddd89c611.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#54b9821b-7594-4a5d-98e3-f6b3e39a2e18#stage#PUBLISHED",
          title: "Scegli un sapone solido",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#54b9821b-7594-4a5d-98e3-f6b3e39a2e18#stage#PUBLISHED",
          title: "Choisissez un savon solide",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#54b9821b-7594-4a5d-98e3-f6b3e39a2e18#stage#PUBLISHED",
          title: "Elige jabón sólido ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#54b9821b-7594-4a5d-98e3-f6b3e39a2e18#stage#PUBLISHED",
          title: "Choose a solid soap",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#54b9821b-7594-4a5d-98e3-f6b3e39a2e18#stage#PUBLISHED",
          title: "Wählen sie eine feste seife",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.089,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "15282b66-ff95-4f39-ba06-5dd8a1ef5c60",
    createdAt: "2023-04-06T14:53:47.578Z",
    updatedAt: "2023-07-24T15:01:23.226Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/medita-per-5-minuti-fafd67e1b598.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#15282b66-ff95-4f39-ba06-5dd8a1ef5c60#stage#PUBLISHED",
          title: "Medita per 5 minuti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#15282b66-ff95-4f39-ba06-5dd8a1ef5c60#stage#PUBLISHED",
          title: "Meditez pendant 5 minutes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#15282b66-ff95-4f39-ba06-5dd8a1ef5c60#stage#PUBLISHED",
          title: "Medita durante 5 minutos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#15282b66-ff95-4f39-ba06-5dd8a1ef5c60#stage#PUBLISHED",
          title: "Meditate for 5 minutes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#15282b66-ff95-4f39-ba06-5dd8a1ef5c60#stage#PUBLISHED",
          title: "Meditieren sie 5 minuten lang",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "66e2eccc-28ea-4934-b8be-f33ca46ec5a4",
    createdAt: "2023-04-06T08:06:09.236Z",
    updatedAt: "2023-07-24T15:00:26.566Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/leggi-un-libro-60dbdce16980.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#66e2eccc-28ea-4934-b8be-f33ca46ec5a4#stage#PUBLISHED",
          title: "Leggi un libro",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#66e2eccc-28ea-4934-b8be-f33ca46ec5a4#stage#PUBLISHED",
          title: "Lisez un livre",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#66e2eccc-28ea-4934-b8be-f33ca46ec5a4#stage#PUBLISHED",
          title: " Lee un libro ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#66e2eccc-28ea-4934-b8be-f33ca46ec5a4#stage#PUBLISHED",
          title: "Read a book",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#66e2eccc-28ea-4934-b8be-f33ca46ec5a4#stage#PUBLISHED",
          title: "Lesen sie ein buch ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "fb55c6da-1a21-4cab-bb78-11ce6d02521a",
    createdAt: "2023-04-06T08:21:58.788Z",
    updatedAt: "2023-07-24T15:00:15.256Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/regalati-una-pianta-795018b0c027.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#fb55c6da-1a21-4cab-bb78-11ce6d02521a#stage#PUBLISHED",
          title: "Regalati una pianta",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#fb55c6da-1a21-4cab-bb78-11ce6d02521a#stage#PUBLISHED",
          title: " Offrez(-vous) une plante",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#fb55c6da-1a21-4cab-bb78-11ce6d02521a#stage#PUBLISHED",
          title: "Regala(te) una planta ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#fb55c6da-1a21-4cab-bb78-11ce6d02521a#stage#PUBLISHED",
          title: "Get a plant",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#fb55c6da-1a21-4cab-bb78-11ce6d02521a#stage#PUBLISHED",
          title: "Schenken sie (sich) eine pflanze",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "57b69752-c85f-49b2-bf66-10533eb2c015",
    createdAt: "2023-04-06T08:37:49.271Z",
    updatedAt: "2023-07-24T15:00:05.292Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/chiudi-il-rubinetto-quando-ti-lavi-i-denti-0252866daa87.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#57b69752-c85f-49b2-bf66-10533eb2c015#stage#PUBLISHED",
          title: "Chiudi il rubinetto mentre ti lavi i denti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#57b69752-c85f-49b2-bf66-10533eb2c015#stage#PUBLISHED",
          title: "Fermez le robinet quand vous vous lavez les dents",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#57b69752-c85f-49b2-bf66-10533eb2c015#stage#PUBLISHED",
          title: "Cierra el grifo cuando te lavas los dientes ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#57b69752-c85f-49b2-bf66-10533eb2c015#stage#PUBLISHED",
          title: "Close the tap when brushing your teeth",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#57b69752-c85f-49b2-bf66-10533eb2c015#stage#PUBLISHED",
          title: "Drehen sie den wasserhahn zu, wenn sie sich die zähne putzen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 33,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "a274a92c-ab03-4424-920e-3e4af3e4acba",
    createdAt: "2023-04-06T13:37:18.089Z",
    updatedAt: "2023-07-24T14:59:49.761Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-una-doccia-di-5-minuti-7b20e428b6cf.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#a274a92c-ab03-4424-920e-3e4af3e4acba#stage#PUBLISHED",
          title: "Fai una doccia di 5 minuti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#a274a92c-ab03-4424-920e-3e4af3e4acba#stage#PUBLISHED",
          title: "Prenez une douche en 5 minutes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#a274a92c-ab03-4424-920e-3e4af3e4acba#stage#PUBLISHED",
          title: "Duchate en 5 minutos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#a274a92c-ab03-4424-920e-3e4af3e4acba#stage#PUBLISHED",
          title: "Take a 5-minute shower",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#a274a92c-ab03-4424-920e-3e4af3e4acba#stage#PUBLISHED",
          title: "Duschen sie 5 minuten lang",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 47.3,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "00c63ef2-b2a4-4ba7-90cc-614ec5e27f55",
    createdAt: "2023-04-20T10:30:52.767Z",
    updatedAt: "2023-07-24T14:58:22.737Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/copri-le-pentole-con-il-coperchio-090eff3cbd8d.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#00c63ef2-b2a4-4ba7-90cc-614ec5e27f55#stage#PUBLISHED",
          title: "Copri le pentole con il coperchio",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#00c63ef2-b2a4-4ba7-90cc-614ec5e27f55#stage#PUBLISHED",
          title: "Mettez des couvercles sur les casseroles",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#00c63ef2-b2a4-4ba7-90cc-614ec5e27f55#stage#PUBLISHED",
          title: "Cubre las ollas con la tapa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#00c63ef2-b2a4-4ba7-90cc-614ec5e27f55#stage#PUBLISHED",
          title: "Cover the pots with the lid",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#00c63ef2-b2a4-4ba7-90cc-614ec5e27f55#stage#PUBLISHED",
          title: "Decken sie die Töpfe mit dem Deckel ab",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "4e2a0415-7b7c-47e5-8533-d04bd6fadc18",
    createdAt: "2023-04-19T11:24:17.617Z",
    updatedAt: "2023-07-24T14:58:04.571Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/chiudi-il-rubinetto-quando-lavi-i-piatti-cbee95115522.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#4e2a0415-7b7c-47e5-8533-d04bd6fadc18#stage#PUBLISHED",
          title: "Chiudi il rubinetto quando lavi i piatti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#4e2a0415-7b7c-47e5-8533-d04bd6fadc18#stage#PUBLISHED",
          title: "Fermez le robinet quand vous faites la vaisselle",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#4e2a0415-7b7c-47e5-8533-d04bd6fadc18#stage#PUBLISHED",
          title: "Cierra el grifo mientras lavas los platos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#4e2a0415-7b7c-47e5-8533-d04bd6fadc18#stage#PUBLISHED",
          title: "Close the tap when you wash the dishes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#4e2a0415-7b7c-47e5-8533-d04bd6fadc18#stage#PUBLISHED",
          title: "Drehen Sie den Wasserhahn beim Geschirrspülen zu",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 16,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "9e06bcba-9e01-47bb-8f65-6ee119cf83ba",
    createdAt: "2023-04-06T08:11:58.639Z",
    updatedAt: "2023-07-24T14:57:13.289Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-tre-respiri-profondi-dececd1b2c69.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#9e06bcba-9e01-47bb-8f65-6ee119cf83ba#stage#PUBLISHED",
          title: "深呼吸三次",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#9e06bcba-9e01-47bb-8f65-6ee119cf83ba#stage#PUBLISHED",
          title: "Fai tre respiri profondi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#9e06bcba-9e01-47bb-8f65-6ee119cf83ba#stage#PUBLISHED",
          title: "Respirez profondément trois fois",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#9e06bcba-9e01-47bb-8f65-6ee119cf83ba#stage#PUBLISHED",
          title: "Toma tres respiraciones profundas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#9e06bcba-9e01-47bb-8f65-6ee119cf83ba#stage#PUBLISHED",
          title: "Take three deep breaths",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#9e06bcba-9e01-47bb-8f65-6ee119cf83ba#stage#PUBLISHED",
          title: "Atmen sie dreimal tief durch",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "bfbd6696-4db1-4a6c-a54b-2633c596753a",
    createdAt: "2023-04-06T12:40:37.964Z",
    updatedAt: "2023-07-24T14:57:02.619Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/mangia-un-pasto-vegetariano-f85144535711.svg",
    featured: true,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#bfbd6696-4db1-4a6c-a54b-2633c596753a#stage#PUBLISHED",
          title: "吃一餐素食",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#bfbd6696-4db1-4a6c-a54b-2633c596753a#stage#PUBLISHED",
          title: "Mangia un pasto vegetariano",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#bfbd6696-4db1-4a6c-a54b-2633c596753a#stage#PUBLISHED",
          title: "Mangez un plat végétarien",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#bfbd6696-4db1-4a6c-a54b-2633c596753a#stage#PUBLISHED",
          title: "Come un menú vegetariano ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#bfbd6696-4db1-4a6c-a54b-2633c596753a#stage#PUBLISHED",
          title: "Eat a vegetarian meal",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#bfbd6696-4db1-4a6c-a54b-2633c596753a#stage#PUBLISHED",
          title: "Essen sie eine vegetarische mahlzeit",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 6.59,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "e5affece-d7f1-49e6-956e-bfcaeae23509",
    createdAt: "2023-04-06T08:52:42.464Z",
    updatedAt: "2023-07-24T14:56:50.012Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spegni-le-luci-500408a782d4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#e5affece-d7f1-49e6-956e-bfcaeae23509#stage#PUBLISHED",
          title: "關燈！",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#e5affece-d7f1-49e6-956e-bfcaeae23509#stage#PUBLISHED",
          title: "Spegni le luci!",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#e5affece-d7f1-49e6-956e-bfcaeae23509#stage#PUBLISHED",
          title: "Eteignez les lumières! ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#e5affece-d7f1-49e6-956e-bfcaeae23509#stage#PUBLISHED",
          title: "¡Apaga las luces!",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#e5affece-d7f1-49e6-956e-bfcaeae23509#stage#PUBLISHED",
          title: "Turn off the lights!",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#e5affece-d7f1-49e6-956e-bfcaeae23509#stage#PUBLISHED",
          title: "Machen sie die lichter aus!",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.1,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "284c22e5-dd41-4674-a3fb-0f2253b6720a",
    createdAt: "2023-04-06T07:55:07.049Z",
    updatedAt: "2023-07-24T14:56:40.702Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/pratica-earthing-42c976eb0846.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#284c22e5-dd41-4674-a3fb-0f2253b6720a#stage#PUBLISHED",
          title: "練習赤腳走路",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#284c22e5-dd41-4674-a3fb-0f2253b6720a#stage#PUBLISHED",
          title: "Pratica l'earthing",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#284c22e5-dd41-4674-a3fb-0f2253b6720a#stage#PUBLISHED",
          title: "Pratiquez l’earthing",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#284c22e5-dd41-4674-a3fb-0f2253b6720a#stage#PUBLISHED",
          title: "Pratica el earthing",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#284c22e5-dd41-4674-a3fb-0f2253b6720a#stage#PUBLISHED",
          title: "Practice earthing",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#284c22e5-dd41-4674-a3fb-0f2253b6720a#stage#PUBLISHED",
          title: "Machen sie earthing",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "946bd5e6-4a8d-4946-977b-fd22e990749f",
    createdAt: "2023-04-06T08:25:01.202Z",
    updatedAt: "2023-07-24T14:56:29.353Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-una-borsa-riutilizzabile-09f99478b129.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#946bd5e6-4a8d-4946-977b-fd22e990749f#stage#PUBLISHED",
          title: "使用可重複使用的袋子",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#946bd5e6-4a8d-4946-977b-fd22e990749f#stage#PUBLISHED",
          title: "Usa una borsa riutilizzabile ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#946bd5e6-4a8d-4946-977b-fd22e990749f#stage#PUBLISHED",
          title: "Servez-vous d'un sac réutilisable ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#946bd5e6-4a8d-4946-977b-fd22e990749f#stage#PUBLISHED",
          title: "Usa una bolsa reutilizable",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#946bd5e6-4a8d-4946-977b-fd22e990749f#stage#PUBLISHED",
          title: "Use a reusable bag",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#946bd5e6-4a8d-4946-977b-fd22e990749f#stage#PUBLISHED",
          title: "Verwenden sie eine wiederverwendbare tasche",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.02,
          energySaving: 0,
          waterSaving: 0.92,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "d9ed1813-6220-4e0f-ab09-810552337ac0",
    createdAt: "2023-04-06T09:23:12.260Z",
    updatedAt: "2023-07-24T14:56:18.405Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/raccogli-un-sacco-di-rifiuti-ca3b4d7b5a1c.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#d9ed1813-6220-4e0f-ab09-810552337ac0#stage#PUBLISHED",
          title: "收集垃圾",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#d9ed1813-6220-4e0f-ab09-810552337ac0#stage#PUBLISHED",
          title: "Raccogli un sacco di rifiuti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#d9ed1813-6220-4e0f-ab09-810552337ac0#stage#PUBLISHED",
          title: "Ramassez un sac de déchets ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#d9ed1813-6220-4e0f-ab09-810552337ac0#stage#PUBLISHED",
          title: "Recoge una bolsa de basura",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#d9ed1813-6220-4e0f-ab09-810552337ac0#stage#PUBLISHED",
          title: "Collect waste",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#d9ed1813-6220-4e0f-ab09-810552337ac0#stage#PUBLISHED",
          title: "Sammeln sie eine tüte Müll",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "00886f1a-63b4-4b0d-a5dc-2aaffa98e473",
    createdAt: "2023-04-19T11:23:18.355Z",
    updatedAt: "2023-07-24T14:56:02.071Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/mangia-un-pasto-pescetariano-7bc3a829704b.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#00886f1a-63b4-4b0d-a5dc-2aaffa98e473#stage#PUBLISHED",
          title: "吃魚素餐",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#00886f1a-63b4-4b0d-a5dc-2aaffa98e473#stage#PUBLISHED",
          title: "Mangia un pasto pescetariano",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#00886f1a-63b4-4b0d-a5dc-2aaffa98e473#stage#PUBLISHED",
          title: "Mangez un plat pesco-végétarien",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#00886f1a-63b4-4b0d-a5dc-2aaffa98e473#stage#PUBLISHED",
          title: "Come un menú pescetariano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#00886f1a-63b4-4b0d-a5dc-2aaffa98e473#stage#PUBLISHED",
          title: "Eat a pescetarian meal",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#00886f1a-63b4-4b0d-a5dc-2aaffa98e473#stage#PUBLISHED",
          title: "Essen sie eine pescetarische mahlzeit",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 5.7,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "4281b80a-896d-4575-acd6-6d22f2b8a297",
    createdAt: "2023-04-06T12:51:15.031Z",
    updatedAt: "2023-07-24T14:55:48.758Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/di-la-tua-per-il-pianeta-65a393df104e.svg",
    featured: true,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#4281b80a-896d-4575-acd6-6d22f2b8a297#stage#PUBLISHED",
          title: "為地球發聲",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#4281b80a-896d-4575-acd6-6d22f2b8a297#stage#PUBLISHED",
          title: "Di' la tua per il Pianeta",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#4281b80a-896d-4575-acd6-6d22f2b8a297#stage#PUBLISHED",
          title: "Exprimez-vous pour la Planète",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#4281b80a-896d-4575-acd6-6d22f2b8a297#stage#PUBLISHED",
          title: "Di la tuya por el Planeta",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#4281b80a-896d-4575-acd6-6d22f2b8a297#stage#PUBLISHED",
          title: "Speak up for the Planet",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#4281b80a-896d-4575-acd6-6d22f2b8a297#stage#PUBLISHED",
          title: "Sagen sie ihre meinung für den Planeten",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "382b29e8-6e15-4016-aeba-2ab2196da3cb",
    createdAt: "2023-04-19T11:00:46.755Z",
    updatedAt: "2023-07-24T14:55:37.325Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-la-lavastoviglie-a-pieno-carico-a8e1cb1faf3b.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#382b29e8-6e15-4016-aeba-2ab2196da3cb#stage#PUBLISHED",
          title: "只在洗碗機滿載時使用",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#382b29e8-6e15-4016-aeba-2ab2196da3cb#stage#PUBLISHED",
          title: "Usa la lavastoviglie a pieno carico",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#382b29e8-6e15-4016-aeba-2ab2196da3cb#stage#PUBLISHED",
          title: "Utilisez le lave-vaisselle quand il est plein",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#382b29e8-6e15-4016-aeba-2ab2196da3cb#stage#PUBLISHED",
          title: "Usa el lavavajillas cuando esté lleno",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#382b29e8-6e15-4016-aeba-2ab2196da3cb#stage#PUBLISHED",
          title: "Use the dishwasher with a full load",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#382b29e8-6e15-4016-aeba-2ab2196da3cb#stage#PUBLISHED",
          title: "Benutzen sie den geschirrspüler bei voller beladung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.842,
          energySaving: 2,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "7386759a-451f-456d-a723-fd8c053f8cbd",
    createdAt: "2023-04-19T09:43:09.368Z",
    updatedAt: "2023-07-24T14:55:26.969Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fatti-cambiare-la-biancheria-solo-quando-necessario-133555ecaf13.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#7386759a-451f-456d-a723-fd8c053f8cbd#stage#PUBLISHED",
          title: "Fatti cambiare la biancheria solo quando necessario",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#7386759a-451f-456d-a723-fd8c053f8cbd#stage#PUBLISHED",
          title: "Faites changer le linge uniquement quand c’est nécessaire",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#7386759a-451f-456d-a723-fd8c053f8cbd#stage#PUBLISHED",
          title: "Cambia toallas y sábanas solo cuando sea necesario",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#7386759a-451f-456d-a723-fd8c053f8cbd#stage#PUBLISHED",
          title: "Get your linen changed only when necessary",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#7386759a-451f-456d-a723-fd8c053f8cbd#stage#PUBLISHED",
          title: "Lassen Sie Ihre Bettwäsche nur bei Bedarf wechseln",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catTravel",
      name: "Travel",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 3.3,
          energySaving: 6.3,
          waterSaving: 117,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "52ce2238-b75d-4e84-8e4a-8a9487f29f9c",
    createdAt: "2023-04-06T09:48:10.705Z",
    updatedAt: "2023-07-24T14:53:19.344Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-le-riunoni-da-remoto-48e59be767f1.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#52ce2238-b75d-4e84-8e4a-8a9487f29f9c#stage#PUBLISHED",
          title: "Fai le riunoni da remoto",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#52ce2238-b75d-4e84-8e4a-8a9487f29f9c#stage#PUBLISHED",
          title: "Faites vos reunions a distance ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#52ce2238-b75d-4e84-8e4a-8a9487f29f9c#stage#PUBLISHED",
          title: "Haz reuniones en remoto",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#52ce2238-b75d-4e84-8e4a-8a9487f29f9c#stage#PUBLISHED",
          title: "Take meetings remotely",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#52ce2238-b75d-4e84-8e4a-8a9487f29f9c#stage#PUBLISHED",
          title: "Halten sie sitzungen aus der ferne ab",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "70536161-0623-4de5-8904-3004456137b6",
    createdAt: "2023-04-19T11:05:22.876Z",
    updatedAt: "2023-07-24T14:53:10.322Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/indossa-abiti-piu-caldi-in-casa-726c10f7b885.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#70536161-0623-4de5-8904-3004456137b6#stage#PUBLISHED",
          title: "Indossa abiti piú caldi in casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#70536161-0623-4de5-8904-3004456137b6#stage#PUBLISHED",
          title: "Mettez des vêtements plus chauds a la maison",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#70536161-0623-4de5-8904-3004456137b6#stage#PUBLISHED",
          title: "Usa ropa de abrigo en casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#70536161-0623-4de5-8904-3004456137b6#stage#PUBLISHED",
          title: "Wear warmer clothes inside the house",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#70536161-0623-4de5-8904-3004456137b6#stage#PUBLISHED",
          title: "Tragen sie wärmere kleidung in innenräumen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.47,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "8d9b0c1d-821e-4621-b726-d3f96e33655a",
    createdAt: "2023-04-19T10:56:05.387Z",
    updatedAt: "2023-07-24T14:53:00.008Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-la-lavatrice-a-pieno-carico-d504cbbaf9ea.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#8d9b0c1d-821e-4621-b726-d3f96e33655a#stage#PUBLISHED",
          title: "Usa la lavatrice a pieno carico",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#8d9b0c1d-821e-4621-b726-d3f96e33655a#stage#PUBLISHED",
          title: "Utilisez le lave-linge quand il est plein",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#8d9b0c1d-821e-4621-b726-d3f96e33655a#stage#PUBLISHED",
          title: "Usa la lavadora cuando esté llena",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#8d9b0c1d-821e-4621-b726-d3f96e33655a#stage#PUBLISHED",
          title: "Use the washing machine at a full load",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#8d9b0c1d-821e-4621-b726-d3f96e33655a#stage#PUBLISHED",
          title: "Benutzen sie die waschmaschine bei voller beladung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 2.65,
          energySaving: 6.3,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "3f9b63db-fc4a-4de4-9859-8f3f67c662fc",
    createdAt: "2023-04-19T10:49:53.115Z",
    updatedAt: "2023-07-24T14:52:39.800Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/sfrutta-la-luce-naturale-in-casa-8a38c128ed3e.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#3f9b63db-fc4a-4de4-9859-8f3f67c662fc#stage#PUBLISHED",
          title: "Sfrutta la luce naturale in casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#3f9b63db-fc4a-4de4-9859-8f3f67c662fc#stage#PUBLISHED",
          title: "Exploitez la lumière naturelle dans la maison",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#3f9b63db-fc4a-4de4-9859-8f3f67c662fc#stage#PUBLISHED",
          title: "Aprovecha la luz natural en casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#3f9b63db-fc4a-4de4-9859-8f3f67c662fc#stage#PUBLISHED",
          title: "Enjoy natural light in your home",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#3f9b63db-fc4a-4de4-9859-8f3f67c662fc#stage#PUBLISHED",
          title: "Nutzen sie das natürliche licht in ihrem wohnung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.84,
          energySaving: 4.38,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "417fcbbe-878a-423a-9380-423d9cfbe8f4",
    createdAt: "2023-04-19T10:41:08.301Z",
    updatedAt: "2023-07-24T14:52:24.137Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-lavatrice-e-asciugatrice-con-programmi-ecologici-bc1fa7c9edc1.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#417fcbbe-878a-423a-9380-423d9cfbe8f4#stage#PUBLISHED",
          title: "Usa lavatrice e asciugatrice con programmi ecologici ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#417fcbbe-878a-423a-9380-423d9cfbe8f4#stage#PUBLISHED",
          title: 'Utiliser le "mode éco" pour le lave-linge et le sèche-linge',
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#417fcbbe-878a-423a-9380-423d9cfbe8f4#stage#PUBLISHED",
          title: "Utiliza programas ecológicos en tu lavadora y secadora",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#417fcbbe-878a-423a-9380-423d9cfbe8f4#stage#PUBLISHED",
          title: "Use the washer and dryer with ecological programs",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#417fcbbe-878a-423a-9380-423d9cfbe8f4#stage#PUBLISHED",
          title: "Verwenden sie waschmaschine und trockner mit sparprogrammen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.5,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "fda0e3e3-86ff-4dc0-8116-f1abdd47c7a4",
    createdAt: "2023-04-19T10:23:17.983Z",
    updatedAt: "2023-07-24T14:52:12.980Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-asciugatrice-a-pieno-carico-1f4aa6f60fb3.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#fda0e3e3-86ff-4dc0-8116-f1abdd47c7a4#stage#PUBLISHED",
          title: "Usa l’asciugatrice a pieno carico",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#fda0e3e3-86ff-4dc0-8116-f1abdd47c7a4#stage#PUBLISHED",
          title: "Utilisez le sèche-linge quand il est plein",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#fda0e3e3-86ff-4dc0-8116-f1abdd47c7a4#stage#PUBLISHED",
          title: "Usa la secadora con la carga completa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#fda0e3e3-86ff-4dc0-8116-f1abdd47c7a4#stage#PUBLISHED",
          title: "Use the dryer at a full load",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#fda0e3e3-86ff-4dc0-8116-f1abdd47c7a4#stage#PUBLISHED",
          title: "Verwenden sie den trockner bei voller beladung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.84,
          energySaving: 2,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "5e444d7f-a2c2-49ca-9f78-b66d590cebd5",
    createdAt: "2023-04-19T10:28:27.333Z",
    updatedAt: "2023-07-24T14:52:02.847Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-la-lavastoviglie-con-programmi-ecologici-b2a09de89c28.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#5e444d7f-a2c2-49ca-9f78-b66d590cebd5#stage#PUBLISHED",
          title: "Usa la lavastoviglie con programmi ecologici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#5e444d7f-a2c2-49ca-9f78-b66d590cebd5#stage#PUBLISHED",
          title: "Utilisez les programmes écologiques de votre lave-vaisselle",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#5e444d7f-a2c2-49ca-9f78-b66d590cebd5#stage#PUBLISHED",
          title: "Programa ecológico en tu lavavajillas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#5e444d7f-a2c2-49ca-9f78-b66d590cebd5#stage#PUBLISHED",
          title: "Use the dishwasher with the ecological programs",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#5e444d7f-a2c2-49ca-9f78-b66d590cebd5#stage#PUBLISHED",
          title: "Verwenden sie den geschirrspüler mit sparprogrammen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.63,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "7b44e3c4-4ec3-4a24-9a29-2e646fb7a1c1",
    createdAt: "2023-04-19T11:26:51.991Z",
    updatedAt: "2023-07-24T14:51:53.673Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/chiudi-il-rubinetto-della-doccia-mentre-ti-insaponi-49bd6c34eab5.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#7b44e3c4-4ec3-4a24-9a29-2e646fb7a1c1#stage#PUBLISHED",
          title: "Chiudi il rubinetto della doccia mentre ti insaponi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#7b44e3c4-4ec3-4a24-9a29-2e646fb7a1c1#stage#PUBLISHED",
          title: "Fermez le robinet de la douche pendant que vous savonnez",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#7b44e3c4-4ec3-4a24-9a29-2e646fb7a1c1#stage#PUBLISHED",
          title: "Cierra el grifo de la ducha mientras te enjabonas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#7b44e3c4-4ec3-4a24-9a29-2e646fb7a1c1#stage#PUBLISHED",
          title: "Close the shower tap while soaping yourself",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#7b44e3c4-4ec3-4a24-9a29-2e646fb7a1c1#stage#PUBLISHED",
          title: "Drehen Sie den Brausehahn zu, während Sie sich einseifen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 19,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "ccba8353-b9ee-4092-9440-67337d1ee7e9",
    createdAt: "2023-04-19T09:59:30.862Z",
    updatedAt: "2023-07-24T14:51:41.604Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/apri-le-finestre-di-sera-in-estate-be6bc472b55e.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#ccba8353-b9ee-4092-9440-67337d1ee7e9#stage#PUBLISHED",
          title: "Apri le finestre di sera in estate",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#ccba8353-b9ee-4092-9440-67337d1ee7e9#stage#PUBLISHED",
          title: "Ouvrez les fenêtres les soirs d'été",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#ccba8353-b9ee-4092-9440-67337d1ee7e9#stage#PUBLISHED",
          title: "Abre las ventanas las noches de verano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#ccba8353-b9ee-4092-9440-67337d1ee7e9#stage#PUBLISHED",
          title: "Open the windows on summer evenings",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#ccba8353-b9ee-4092-9440-67337d1ee7e9#stage#PUBLISHED",
          title: "Öffnen sie im sommer die fenster nachts",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 10.1,
          energySaving: 24,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "5028cb94-c9ec-475b-9d28-8221b57be85e",
    createdAt: "2023-04-19T12:01:55.431Z",
    updatedAt: "2023-07-24T14:50:37.852Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-una-cena-a-lume-di-candela-179145567131.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#5028cb94-c9ec-475b-9d28-8221b57be85e#stage#PUBLISHED",
          title: "Fai una cena a lume di candela",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#5028cb94-c9ec-475b-9d28-8221b57be85e#stage#PUBLISHED",
          title: "Faites un diner aux chandelles",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#5028cb94-c9ec-475b-9d28-8221b57be85e#stage#PUBLISHED",
          title: "Cena a la luz de las velas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#5028cb94-c9ec-475b-9d28-8221b57be85e#stage#PUBLISHED",
          title: "Have a candlelit dinner",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#5028cb94-c9ec-475b-9d28-8221b57be85e#stage#PUBLISHED",
          title: "Essen sie bei kerzenschein zu abend",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.13,
          energySaving: 0.3,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "040a4ec4-729e-41df-a4cf-41bfb301028d",
    createdAt: "2023-04-06T09:32:56.412Z",
    updatedAt: "2023-07-24T14:50:27.729Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spegni-la-videocamera-quando-non-serve-d6a648594df6.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#040a4ec4-729e-41df-a4cf-41bfb301028d#stage#PUBLISHED",
          title: "Spegni la videocamera quando non serve",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#040a4ec4-729e-41df-a4cf-41bfb301028d#stage#PUBLISHED",
          title: "Éteignez la caméra quand elle ne sert pas ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#040a4ec4-729e-41df-a4cf-41bfb301028d#stage#PUBLISHED",
          title: "Apaga la videocámara cuando no la necesites",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#040a4ec4-729e-41df-a4cf-41bfb301028d#stage#PUBLISHED",
          title: "Turn off the camera when you don't need it",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#040a4ec4-729e-41df-a4cf-41bfb301028d#stage#PUBLISHED",
          title: "Schalten sie die kamera aus, wenn sie sie nicht benötigen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.14,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "5af51368-a16f-46fd-9f23-b885fbacc461",
    createdAt: "2023-04-19T10:13:32.775Z",
    updatedAt: "2023-07-24T14:50:17.947Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spegni-il-pc-quando-non-lo-utilizzi-350fa505cc6f.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#5af51368-a16f-46fd-9f23-b885fbacc461#stage#PUBLISHED",
          title: "Spegni il PC quando non lo utilizzi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#5af51368-a16f-46fd-9f23-b885fbacc461#stage#PUBLISHED",
          title: "Éteignez votre ordinateur quand vous ne l'utilisez pas",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#5af51368-a16f-46fd-9f23-b885fbacc461#stage#PUBLISHED",
          title: "Apaga el ordenador cuando no lo utilices ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#5af51368-a16f-46fd-9f23-b885fbacc461#stage#PUBLISHED",
          title: "Turn off your computer when you are not using it",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#5af51368-a16f-46fd-9f23-b885fbacc461#stage#PUBLISHED",
          title: "Schalten sie den computer aus, wenn er nicht benutzt wird",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.013,
          energySaving: 0.032,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "1f991fcf-2829-421d-8674-8d1b8c7d387b",
    createdAt: "2023-04-19T09:36:54.669Z",
    updatedAt: "2023-07-24T14:50:07.881Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/chiudi-le-tende-o-gli-oscuranti-in-estate-eccd18dfa19b.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#1f991fcf-2829-421d-8674-8d1b8c7d387b#stage#PUBLISHED",
          title: "Chiudi le tende o gli oscuranti in estate",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#1f991fcf-2829-421d-8674-8d1b8c7d387b#stage#PUBLISHED",
          title: "Tirez les rideaux ou les stores en été ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#1f991fcf-2829-421d-8674-8d1b8c7d387b#stage#PUBLISHED",
          title: "Cierra toldos o persianas en verano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#1f991fcf-2829-421d-8674-8d1b8c7d387b#stage#PUBLISHED",
          title: "Close the curtains or blinds in the summer",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#1f991fcf-2829-421d-8674-8d1b8c7d387b#stage#PUBLISHED",
          title: "Schließen Sie im Sommer Vorhänge oder Jalousien",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.54,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "1b00022d-da45-4595-b72c-325478c41178",
    createdAt: "2023-04-05T16:12:01.161Z",
    updatedAt: "2023-07-24T14:49:58.970Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ascolta-la-musica-scaricata-020573087a3b.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#1b00022d-da45-4595-b72c-325478c41178#stage#PUBLISHED",
          title: "Ascolta musica scaricata ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#1b00022d-da45-4595-b72c-325478c41178#stage#PUBLISHED",
          title: "Ecoutez de la musique déjà déchargée ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#1b00022d-da45-4595-b72c-325478c41178#stage#PUBLISHED",
          title: "Descarga la musica que quieras escuchar ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#1b00022d-da45-4595-b72c-325478c41178#stage#PUBLISHED",
          title: "Listen to pre downloaded music",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#1b00022d-da45-4595-b72c-325478c41178#stage#PUBLISHED",
          title: "Hören sie heruntergeladene musik",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.11,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "1099cda5-83e9-49e2-8286-5929552a9f47",
    createdAt: "2023-04-19T11:15:10.061Z",
    updatedAt: "2023-07-24T14:49:48.820Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-prodotti-biologici-4ce686115125.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#1099cda5-83e9-49e2-8286-5929552a9f47#stage#PUBLISHED",
          title: "Scegli prodotti biologici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#1099cda5-83e9-49e2-8286-5929552a9f47#stage#PUBLISHED",
          title: "Choisissez des produits bio",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#1099cda5-83e9-49e2-8286-5929552a9f47#stage#PUBLISHED",
          title: "Elige productos biológicos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#1099cda5-83e9-49e2-8286-5929552a9f47#stage#PUBLISHED",
          title: "Choose organic products",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#1099cda5-83e9-49e2-8286-5929552a9f47#stage#PUBLISHED",
          title: "Wählen sie bioprodukte",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.019,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "2b839bfb-5e7f-4487-90ed-fdfb6273e2f5",
    createdAt: "2023-04-05T14:22:50.498Z",
    updatedAt: "2023-07-24T14:49:39.336Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/consuma-tutta-la-tua-spesa-d8cf8a784bdb.svg",
    featured: true,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#2b839bfb-5e7f-4487-90ed-fdfb6273e2f5#stage#PUBLISHED",
          title: "Consuma tutta la tua spesa",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#2b839bfb-5e7f-4487-90ed-fdfb6273e2f5#stage#PUBLISHED",
          title: "Mangez les courses que vous faites",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#2b839bfb-5e7f-4487-90ed-fdfb6273e2f5#stage#PUBLISHED",
          title: "Consume toda la compra",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#2b839bfb-5e7f-4487-90ed-fdfb6273e2f5#stage#PUBLISHED",
          title: "Consume all your groceries",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#2b839bfb-5e7f-4487-90ed-fdfb6273e2f5#stage#PUBLISHED",
          title: "Verbrauchen sie ihren gesamten einkauf",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.16,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "40e5d3f9-0fbe-4ae7-8d0b-b4cf77a5b6c4",
    createdAt: "2023-04-06T07:53:53.195Z",
    updatedAt: "2023-07-24T14:49:24.475Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/prendi-la-bici-invece-dei-mezzi-pubblici-dae94016c198.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#40e5d3f9-0fbe-4ae7-8d0b-b4cf77a5b6c4#stage#PUBLISHED",
          title: "Prendi la bici invece dei mezzi pubblici ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#40e5d3f9-0fbe-4ae7-8d0b-b4cf77a5b6c4#stage#PUBLISHED",
          title: "Prenez le vélo au lieu des transports en commun",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#40e5d3f9-0fbe-4ae7-8d0b-b4cf77a5b6c4#stage#PUBLISHED",
          title: "Coge la bici en lugar del transporte público",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#40e5d3f9-0fbe-4ae7-8d0b-b4cf77a5b6c4#stage#PUBLISHED",
          title: "Ride a bike instead of public transport",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#40e5d3f9-0fbe-4ae7-8d0b-b4cf77a5b6c4#stage#PUBLISHED",
          title: "Mit dem Fahrrad statt mit öffentlichen Verkehrsmitteln",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.65,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "fbfe8a80-951d-4801-8b91-4821e9621bd6",
    createdAt: "2023-04-05T15:56:55.721Z",
    updatedAt: "2023-07-24T14:49:12.444Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spostati-con-i-mezzi-pubblici-ad338ef3d0ab.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#fbfe8a80-951d-4801-8b91-4821e9621bd6#stage#PUBLISHED",
          title: "Spostati con i mezzi pubblici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#fbfe8a80-951d-4801-8b91-4821e9621bd6#stage#PUBLISHED",
          title: "Déplacez-vous en transports en commun ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#fbfe8a80-951d-4801-8b91-4821e9621bd6#stage#PUBLISHED",
          title: "Muévete en transporte público",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#fbfe8a80-951d-4801-8b91-4821e9621bd6#stage#PUBLISHED",
          title: "Get around with public transport",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#fbfe8a80-951d-4801-8b91-4821e9621bd6#stage#PUBLISHED",
          title: "Fahren sie mit den öffentlichen verkehrsmitteln ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.05,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "c880bd50-6e09-45dd-bf8d-096ee0c0d2bc",
    createdAt: "2023-04-06T12:40:20.939Z",
    updatedAt: "2023-07-24T14:49:01.867Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/prendi-la-bici-invece-di-auto-2f1c99c9f858.svg",
    featured: true,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#c880bd50-6e09-45dd-bf8d-096ee0c0d2bc#stage#PUBLISHED",
          title: "Prendi la bici invece dell’auto",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#c880bd50-6e09-45dd-bf8d-096ee0c0d2bc#stage#PUBLISHED",
          title: "Prenez le vélo au lieu de la voiture",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#c880bd50-6e09-45dd-bf8d-096ee0c0d2bc#stage#PUBLISHED",
          title: "Coge la bici en lugar del coche",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#c880bd50-6e09-45dd-bf8d-096ee0c0d2bc#stage#PUBLISHED",
          title: "Take the bike instead of the car",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#c880bd50-6e09-45dd-bf8d-096ee0c0d2bc#stage#PUBLISHED",
          title: "Nehmen sie das fahrrad statt des autos",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.7,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "d2e0d065-502f-47da-a037-d96f88984862",
    createdAt: "2023-04-06T08:14:57.390Z",
    updatedAt: "2023-07-24T14:48:51.951Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/cammina-invece-di-prendere-i-mezzi-pubblici-63170cc7b4b2.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#d2e0d065-502f-47da-a037-d96f88984862#stage#PUBLISHED",
          title: "Cammina invece di prendere i mezzi pubblici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#d2e0d065-502f-47da-a037-d96f88984862#stage#PUBLISHED",
          title: "Marchez au lieu de prendre les transports en commun",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#d2e0d065-502f-47da-a037-d96f88984862#stage#PUBLISHED",
          title: "Camina en lugar de coger el transporte público",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#d2e0d065-502f-47da-a037-d96f88984862#stage#PUBLISHED",
          title: "Walk instead of taking public transport",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#d2e0d065-502f-47da-a037-d96f88984862#stage#PUBLISHED",
          title: "Zu Fuß gehen statt öffentliche Verkehrsmittel benutzen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.13,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c",
    createdAt: "2023-04-06T12:45:38.285Z",
    updatedAt: "2023-07-24T14:48:41.556Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/prendi-i-mezzi-pubblici-invece-del-taxi-765ff0d32eb3.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c#stage#PUBLISHED",
          title: "Prendi i mezzi pubblici invece del taxi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c#stage#PUBLISHED",
          title: "Prenez les transports en commun plutôt que le taxi ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c#stage#PUBLISHED",
          title: "Coge el transporte público en lugar del taxi",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c#stage#PUBLISHED",
          title: "Take public transport instead of a taxi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#bbe6a90a-2fb8-4078-94e6-ae7e63e64f6c#stage#PUBLISHED",
          title: "Nehmen sie öffentliche verkehrsmittel statt eines taxis",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.05,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "77f0fc2f-7ef9-46b6-8c61-121e708a444f",
    createdAt: "2023-04-06T09:49:23.936Z",
    updatedAt: "2023-07-24T14:48:31.236Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/cammina-invece-di-prendere-l-auto-aeaf66c5b3ca.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#77f0fc2f-7ef9-46b6-8c61-121e708a444f#stage#PUBLISHED",
          title: "Cammina invece di prendere l'auto",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#77f0fc2f-7ef9-46b6-8c61-121e708a444f#stage#PUBLISHED",
          title: "Marchez au lieu de prendre la voiture",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#77f0fc2f-7ef9-46b6-8c61-121e708a444f#stage#PUBLISHED",
          title: "Camina en lugar de coger el coche",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#77f0fc2f-7ef9-46b6-8c61-121e708a444f#stage#PUBLISHED",
          title: "Walk instead of taking the car",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#77f0fc2f-7ef9-46b6-8c61-121e708a444f#stage#PUBLISHED",
          title: "Gehen sie zu fuss, anstatt das auto zu nehmen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.34,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "2050f42b-18ff-4187-8352-5366a7f8e6d9",
    createdAt: "2023-04-19T11:11:44.694Z",
    updatedAt: "2023-07-24T14:48:21.213Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/utilizza-un-fazzoletto-di-stoffa-9f5da270c9f2.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#2050f42b-18ff-4187-8352-5366a7f8e6d9#stage#PUBLISHED",
          title: "Utilizza un fazzoletto di stoffa",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#2050f42b-18ff-4187-8352-5366a7f8e6d9#stage#PUBLISHED",
          title: "Utilisez des mouchoirs en tissu",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#2050f42b-18ff-4187-8352-5366a7f8e6d9#stage#PUBLISHED",
          title: "Usa un pañuelo de tela",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#2050f42b-18ff-4187-8352-5366a7f8e6d9#stage#PUBLISHED",
          title: "Use a cloth handkerchief",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#2050f42b-18ff-4187-8352-5366a7f8e6d9#stage#PUBLISHED",
          title: "Verwenden Sie ein Stofftaschentuch",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.05,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "8c1efd59-dc9d-4baa-80a3-57b0ce58e8c3",
    createdAt: "2023-04-19T11:51:33.422Z",
    updatedAt: "2023-07-24T14:48:09.725Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scambia-i-tuoi-abiti-df0b9d7f1d1a.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#8c1efd59-dc9d-4baa-80a3-57b0ce58e8c3#stage#PUBLISHED",
          title: "Scambia i tuoi abiti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#8c1efd59-dc9d-4baa-80a3-57b0ce58e8c3#stage#PUBLISHED",
          title: "Echangez vos vetements",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#8c1efd59-dc9d-4baa-80a3-57b0ce58e8c3#stage#PUBLISHED",
          title: "Intercambia tu ropa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#8c1efd59-dc9d-4baa-80a3-57b0ce58e8c3#stage#PUBLISHED",
          title: "Swap your clothes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#8c1efd59-dc9d-4baa-80a3-57b0ce58e8c3#stage#PUBLISHED",
          title: "Tauschen Sie Ihre Kleidung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 16,
          energySaving: 0,
          waterSaving: 1250,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "c02c13db-feba-4c03-b302-38b9e50cea5c",
    createdAt: "2023-04-05T15:22:49.493Z",
    updatedAt: "2023-07-24T14:48:00.166Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-abiti-di-seconda-mano-355ae374bc9a.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#c02c13db-feba-4c03-b302-38b9e50cea5c#stage#PUBLISHED",
          title: "Scegli abiti di seconda mano",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#c02c13db-feba-4c03-b302-38b9e50cea5c#stage#PUBLISHED",
          title: " Choisissez des vêtements d’occasion ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#c02c13db-feba-4c03-b302-38b9e50cea5c#stage#PUBLISHED",
          title: "Escoge prendas de segunda mano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#c02c13db-feba-4c03-b302-38b9e50cea5c#stage#PUBLISHED",
          title: "Choose secondhand clothes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#c02c13db-feba-4c03-b302-38b9e50cea5c#stage#PUBLISHED",
          title: "Wählen sie kleidung aus zweiter hand",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 16,
          energySaving: 0,
          waterSaving: 1250,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "29ed63e6-e82f-4026-b82b-1e1fa52c57d6",
    createdAt: "2023-04-06T08:01:11.510Z",
    updatedAt: "2023-07-24T14:47:50.090Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scambia-un-libro-0536aec1977d.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#29ed63e6-e82f-4026-b82b-1e1fa52c57d6#stage#PUBLISHED",
          title: "Scambia un libro",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#29ed63e6-e82f-4026-b82b-1e1fa52c57d6#stage#PUBLISHED",
          title: "Echangez des livres",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#29ed63e6-e82f-4026-b82b-1e1fa52c57d6#stage#PUBLISHED",
          title: "Intercambia un libro",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#29ed63e6-e82f-4026-b82b-1e1fa52c57d6#stage#PUBLISHED",
          title: "Swap a book",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#29ed63e6-e82f-4026-b82b-1e1fa52c57d6#stage#PUBLISHED",
          title: "Tauschen sie ein buch",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 3,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "79dc2a88-68db-4e0e-8f25-9a5148de5747",
    createdAt: "2023-04-06T14:32:38.923Z",
    updatedAt: "2023-07-24T14:47:41.678Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/dona-i-tuoi-abiti-0e825527d22e.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#79dc2a88-68db-4e0e-8f25-9a5148de5747#stage#PUBLISHED",
          title: "Dona i tuoi abiti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#79dc2a88-68db-4e0e-8f25-9a5148de5747#stage#PUBLISHED",
          title: "Donnez vos vêtements",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#79dc2a88-68db-4e0e-8f25-9a5148de5747#stage#PUBLISHED",
          title: "Dona tus prendas ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#79dc2a88-68db-4e0e-8f25-9a5148de5747#stage#PUBLISHED",
          title: "Donate your clothes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#79dc2a88-68db-4e0e-8f25-9a5148de5747#stage#PUBLISHED",
          title: "Spenden sie ihre kleidung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "d85be0aa-7924-4eaf-b20f-a5c228cac0a2",
    createdAt: "2023-04-06T14:24:37.725Z",
    updatedAt: "2023-07-24T14:47:23.081Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-una-donazione-c632d58ddc1c.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#d85be0aa-7924-4eaf-b20f-a5c228cac0a2#stage#PUBLISHED",
          title: "Fai una donazione",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#d85be0aa-7924-4eaf-b20f-a5c228cac0a2#stage#PUBLISHED",
          title: "Faites un don ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#d85be0aa-7924-4eaf-b20f-a5c228cac0a2#stage#PUBLISHED",
          title: "Haz una donación ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#d85be0aa-7924-4eaf-b20f-a5c228cac0a2#stage#PUBLISHED",
          title: "Make a donation",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#d85be0aa-7924-4eaf-b20f-a5c228cac0a2#stage#PUBLISHED",
          title: "Machen sie eine spende",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "305b22fe-aaf8-423d-ab13-baec31d35197",
    createdAt: "2023-04-06T08:55:24.256Z",
    updatedAt: "2023-07-24T14:47:12.466Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/invita-le-persone-ad-usare-aworld-19c36424c1a9.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#305b22fe-aaf8-423d-ab13-baec31d35197#stage#PUBLISHED",
          title: "Invita le persone ad usare AWorld",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#305b22fe-aaf8-423d-ab13-baec31d35197#stage#PUBLISHED",
          title: "Invitez les personnes à utiliser AWorld",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#305b22fe-aaf8-423d-ab13-baec31d35197#stage#PUBLISHED",
          title: "Invita a las personas a usar AWorld",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#305b22fe-aaf8-423d-ab13-baec31d35197#stage#PUBLISHED",
          title: "Invite people to use AWorld",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#305b22fe-aaf8-423d-ab13-baec31d35197#stage#PUBLISHED",
          title: "Fordern sie die menschen auf, AWorld zu benutzen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "ff50cfa0-bd4f-4d95-8907-49fcb80d675b",
    createdAt: "2023-04-20T10:36:22.489Z",
    updatedAt: "2023-07-24T14:47:01.506Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/porta-i-tuoi-prodotti-travel-size-274156aac342.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#ff50cfa0-bd4f-4d95-8907-49fcb80d675b#stage#PUBLISHED",
          title: "Porta i tuoi prodotti travel size",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#ff50cfa0-bd4f-4d95-8907-49fcb80d675b#stage#PUBLISHED",
          title: "Emportez vos produits en format voyage",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#ff50cfa0-bd4f-4d95-8907-49fcb80d675b#stage#PUBLISHED",
          title: "Lleva tus productos en formato viaje",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#ff50cfa0-bd4f-4d95-8907-49fcb80d675b#stage#PUBLISHED",
          title: "Bring your travel size products",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#ff50cfa0-bd4f-4d95-8907-49fcb80d675b#stage#PUBLISHED",
          title: "Bringen sie ihre eigenen produkte in Reisegrösse mit",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catTravel",
      name: "Travel",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "034b5034-1ef3-4d29-aa3d-802798bf6c62",
    createdAt: "2023-04-19T11:04:21.936Z",
    updatedAt: "2023-07-24T14:46:51.760Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ricicla-la-carta-d0cef3d2a484.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#034b5034-1ef3-4d29-aa3d-802798bf6c62#stage#PUBLISHED",
          title: "Ricicla la carta",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#034b5034-1ef3-4d29-aa3d-802798bf6c62#stage#PUBLISHED",
          title: "Recyclez le papier",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#034b5034-1ef3-4d29-aa3d-802798bf6c62#stage#PUBLISHED",
          title: "Recicla el papel",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#034b5034-1ef3-4d29-aa3d-802798bf6c62#stage#PUBLISHED",
          title: "Recycle paper",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#034b5034-1ef3-4d29-aa3d-802798bf6c62#stage#PUBLISHED",
          title: "Recyceln Sie Papier",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.04,
          energySaving: 2,
          waterSaving: 2,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "8d7cd930-4de4-4e89-b446-d1100afc6b13",
    createdAt: "2023-04-19T11:01:12.502Z",
    updatedAt: "2023-07-24T14:46:42.254Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ricicla-il-vetro-3d2620bc00f7.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#8d7cd930-4de4-4e89-b446-d1100afc6b13#stage#PUBLISHED",
          title: "Ricicla il vetro",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#8d7cd930-4de4-4e89-b446-d1100afc6b13#stage#PUBLISHED",
          title: "Recyclez le verre",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#8d7cd930-4de4-4e89-b446-d1100afc6b13#stage#PUBLISHED",
          title: "Recicla el vidrio",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#8d7cd930-4de4-4e89-b446-d1100afc6b13#stage#PUBLISHED",
          title: "Recycle glass",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#8d7cd930-4de4-4e89-b446-d1100afc6b13#stage#PUBLISHED",
          title: "Recyceln Sie Glas",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.03,
          energySaving: 1,
          waterSaving: 1,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "5063ec83-af87-4398-811e-1876c306e378",
    createdAt: "2023-04-19T10:57:27.343Z",
    updatedAt: "2023-07-24T14:45:40.439Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ricicla-alluminio-33acbabaa72d.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#5063ec83-af87-4398-811e-1876c306e378#stage#PUBLISHED",
          title: "Ricicla l’alluminio",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#5063ec83-af87-4398-811e-1876c306e378#stage#PUBLISHED",
          title: "Recyclez l’aluminium",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#5063ec83-af87-4398-811e-1876c306e378#stage#PUBLISHED",
          title: "Recicla el aluminio",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#5063ec83-af87-4398-811e-1876c306e378#stage#PUBLISHED",
          title: "Recycle aluminum",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#5063ec83-af87-4398-811e-1876c306e378#stage#PUBLISHED",
          title: "Recyceln Sie Aluminium",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.05,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "1e62951c-d794-4c5e-a5c4-5b5897acbd6a",
    createdAt: "2023-04-19T10:53:49.448Z",
    updatedAt: "2023-07-24T14:45:28.013Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/stampa-in-bianco-e-nero-e-fronte-e-retro-f14f65253057.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#1e62951c-d794-4c5e-a5c4-5b5897acbd6a#stage#PUBLISHED",
          title: "Stampa in bianco/nero e fronte/retro",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#1e62951c-d794-4c5e-a5c4-5b5897acbd6a#stage#PUBLISHED",
          title: "Imprimez en noir et blanc et recto/verso",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#1e62951c-d794-4c5e-a5c4-5b5897acbd6a#stage#PUBLISHED",
          title: "Impresión en b/n y a dos caras",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#1e62951c-d794-4c5e-a5c4-5b5897acbd6a#stage#PUBLISHED",
          title: "Print it in black/white and front/back",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#1e62951c-d794-4c5e-a5c4-5b5897acbd6a#stage#PUBLISHED",
          title: "Drucken Sie in schwarz/weiß und beidseitig",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.11,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "09fbd321-db10-4b54-a658-790240f9745d",
    createdAt: "2023-04-19T10:29:35.392Z",
    updatedAt: "2023-07-24T14:44:22.721Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-carta-igienica-di-bambu-9d6e60e5786d.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#09fbd321-db10-4b54-a658-790240f9745d#stage#PUBLISHED",
          title: "Scegli carta igienica di bambù",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#09fbd321-db10-4b54-a658-790240f9745d#stage#PUBLISHED",
          title: "Choisissez du papier toilette en bambou",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#09fbd321-db10-4b54-a658-790240f9745d#stage#PUBLISHED",
          title: "Elige papel higiénico de bambú",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#09fbd321-db10-4b54-a658-790240f9745d#stage#PUBLISHED",
          title: "Choose bamboo toilet paper",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#09fbd321-db10-4b54-a658-790240f9745d#stage#PUBLISHED",
          title: "Wählen Sie Toilettenpapier aus Bambus",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.003,
          energySaving: 0.6,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "ab981b4c-a7a0-4cbf-9cfb-e81980a5291e",
    createdAt: "2023-04-06T13:26:48.109Z",
    updatedAt: "2023-07-24T14:44:13.095Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/riutilizza-tazza-caffe-104ec412503e.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#ab981b4c-a7a0-4cbf-9cfb-e81980a5291e#stage#PUBLISHED",
          title: "Riutilizza la tua tazza da caffè",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#ab981b4c-a7a0-4cbf-9cfb-e81980a5291e#stage#PUBLISHED",
          title: " Réutilisez votre tasse a cafe",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#ab981b4c-a7a0-4cbf-9cfb-e81980a5291e#stage#PUBLISHED",
          title: "Reutiliza tu taza de café ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#ab981b4c-a7a0-4cbf-9cfb-e81980a5291e#stage#PUBLISHED",
          title: "Reuse your coffee cup",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#ab981b4c-a7a0-4cbf-9cfb-e81980a5291e#stage#PUBLISHED",
          title: "Verwenden sie ihre kaffeetasse wieder",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.01,
          energySaving: 0,
          waterSaving: 1.7,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "e5fe5392-ac78-43a4-8ee4-9ac8639795d2",
    createdAt: "2023-04-06T13:13:53.813Z",
    updatedAt: "2023-07-24T14:44:02.998Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ricicla-la-plastica-3c4f6250b42c.svg",
    featured: true,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#e5fe5392-ac78-43a4-8ee4-9ac8639795d2#stage#PUBLISHED",
          title: "Ricicla la plastica",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#e5fe5392-ac78-43a4-8ee4-9ac8639795d2#stage#PUBLISHED",
          title: "recyclez le plastique",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#e5fe5392-ac78-43a4-8ee4-9ac8639795d2#stage#PUBLISHED",
          title: "Recicla el plástico",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#e5fe5392-ac78-43a4-8ee4-9ac8639795d2#stage#PUBLISHED",
          title: "Recycle plastic",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#e5fe5392-ac78-43a4-8ee4-9ac8639795d2#stage#PUBLISHED",
          title: " Recyceln sie plastik ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.316,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "9011da56-0757-4f8b-aa53-cead69199c8e",
    createdAt: "2023-04-06T08:18:30.660Z",
    updatedAt: "2023-07-24T14:43:44.528Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ri-usa-la-tua-bottiglia-3297bbd93a1f.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#9011da56-0757-4f8b-aa53-cead69199c8e#stage#PUBLISHED",
          title: "Ri-usa la tua borraccia",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#9011da56-0757-4f8b-aa53-cead69199c8e#stage#PUBLISHED",
          title: "Re-utilisez votre bouteille",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#9011da56-0757-4f8b-aa53-cead69199c8e#stage#PUBLISHED",
          title: "Re-usa tu botella",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#9011da56-0757-4f8b-aa53-cead69199c8e#stage#PUBLISHED",
          title: "Reuse your water bottle",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#9011da56-0757-4f8b-aa53-cead69199c8e#stage#PUBLISHED",
          title: "Verwenden sie ihre Flasche wieder ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.079,
          energySaving: 0,
          waterSaving: 4.6,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "66ba9e13-c4e5-4bbb-958a-033fa1e38f79",
    createdAt: "2023-05-26T14:05:27.842Z",
    updatedAt: "2023-07-24T14:43:34.490Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/05/partecipa-a-un-evento-e-impara-8a609ad1630c.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#66ba9e13-c4e5-4bbb-958a-033fa1e38f79#stage#PUBLISHED",
          title: "Partecipa a un evento e impara",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#66ba9e13-c4e5-4bbb-958a-033fa1e38f79#stage#PUBLISHED",
          title: "Participez à un événement et apprenez",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#66ba9e13-c4e5-4bbb-958a-033fa1e38f79#stage#PUBLISHED",
          title: "Participa en un evento y adquiere conocimientos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#66ba9e13-c4e5-4bbb-958a-033fa1e38f79#stage#PUBLISHED",
          title: "Take part and learn",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#66ba9e13-c4e5-4bbb-958a-033fa1e38f79#stage#PUBLISHED",
          title: "Nehmen Sie an einer Veranstaltung teil und lernen Sie",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "063f5fa9-0172-480e-bd8e-c5e251030936",
    createdAt: "2023-04-06T12:42:03.223Z",
    updatedAt: "2023-07-24T14:43:17.436Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/stacca-la-spina-7bc76f367be8.svg",
    featured: true,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#063f5fa9-0172-480e-bd8e-c5e251030936#stage#PUBLISHED",
          title: "拔下插頭",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#063f5fa9-0172-480e-bd8e-c5e251030936#stage#PUBLISHED",
          title: "Stacca la spina",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#063f5fa9-0172-480e-bd8e-c5e251030936#stage#PUBLISHED",
          title: "Debranchez la prise",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#063f5fa9-0172-480e-bd8e-c5e251030936#stage#PUBLISHED",
          title: "Desenchufa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#063f5fa9-0172-480e-bd8e-c5e251030936#stage#PUBLISHED",
          title: "Unplug the plug",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#063f5fa9-0172-480e-bd8e-c5e251030936#stage#PUBLISHED",
          title: " Ziehen sie den stecker",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.55,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "3f4c2071-4e38-452d-98a6-2674820ffd5e",
    createdAt: "2023-04-06T09:42:28.919Z",
    updatedAt: "2023-07-24T14:42:56.576Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-regali-fatti-a-mano-cef5fc6a283c.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#3f4c2071-4e38-452d-98a6-2674820ffd5e#stage#PUBLISHED",
          title: "Fai regali fatti a mano",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#3f4c2071-4e38-452d-98a6-2674820ffd5e#stage#PUBLISHED",
          title: "Faites des cadeaux maison",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#3f4c2071-4e38-452d-98a6-2674820ffd5e#stage#PUBLISHED",
          title: "Regala cosas hechas a mano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#3f4c2071-4e38-452d-98a6-2674820ffd5e#stage#PUBLISHED",
          title: "Give homemade gifts",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#3f4c2071-4e38-452d-98a6-2674820ffd5e#stage#PUBLISHED",
          title: "Machen sie handgemachte Geschenke ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "3ace6230-553d-4088-b6d5-349c58808ba1",
    createdAt: "2023-04-19T10:42:33.827Z",
    updatedAt: "2023-07-24T14:42:39.412Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-prodotti-sfusi-2ac5cdeefa6e.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#3ace6230-553d-4088-b6d5-349c58808ba1#stage#PUBLISHED",
          title: "Scegli prodotti sfusi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#3ace6230-553d-4088-b6d5-349c58808ba1#stage#PUBLISHED",
          title: "Choisissez les produits en vrac",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#3ace6230-553d-4088-b6d5-349c58808ba1#stage#PUBLISHED",
          title: "Elige productos a granel",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#3ace6230-553d-4088-b6d5-349c58808ba1#stage#PUBLISHED",
          title: "Choose bulk products",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#3ace6230-553d-4088-b6d5-349c58808ba1#stage#PUBLISHED",
          title: "Wӓhlen sie auslaufende produkte",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.08,
          energySaving: 0,
          waterSaving: 4.6,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "8035a71f-e312-48b6-a180-b21da2e8edf0",
    createdAt: "2023-04-06T13:10:07.141Z",
    updatedAt: "2023-07-24T14:42:22.191Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/prenota-un-alloggio-eco-friendly-075bca700dfa.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#8035a71f-e312-48b6-a180-b21da2e8edf0#stage#PUBLISHED",
          title: "Prenota un alloggio eco-friendly",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#8035a71f-e312-48b6-a180-b21da2e8edf0#stage#PUBLISHED",
          title: "Réservez un hébergement écologique",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#8035a71f-e312-48b6-a180-b21da2e8edf0#stage#PUBLISHED",
          title: "Reserva un alojamiento ecológico",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#8035a71f-e312-48b6-a180-b21da2e8edf0#stage#PUBLISHED",
          title: "Book an eco-friendly accommodation",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#8035a71f-e312-48b6-a180-b21da2e8edf0#stage#PUBLISHED",
          title: "Buchen sie eine umweltfreundliche unterkunft ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catTravel",
      name: "Travel",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.25,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "e4afef07-207d-4ab4-8bac-da3f148db793",
    createdAt: "2023-04-06T08:10:02.581Z",
    updatedAt: "2023-07-24T14:42:13.248Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/prendi-il-treno-1c03dc558803.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#e4afef07-207d-4ab4-8bac-da3f148db793#stage#PUBLISHED",
          title: "Prendi il treno invece dell'aereo",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#e4afef07-207d-4ab4-8bac-da3f148db793#stage#PUBLISHED",
          title: "Prenez le train au lieu de l'avion",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#e4afef07-207d-4ab4-8bac-da3f148db793#stage#PUBLISHED",
          title: "Coge el tren en lugar del avión ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#e4afef07-207d-4ab4-8bac-da3f148db793#stage#PUBLISHED",
          title: "Take the train instead of the plane",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#e4afef07-207d-4ab4-8bac-da3f148db793#stage#PUBLISHED",
          title: "Nehmen sie den Zug anstatt des Flugzeugs ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catTravel",
      name: "Travel",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 48.95,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "e91ed06f-7b21-4430-aabb-408546b9bcb3",
    createdAt: "2023-04-05T14:48:03.808Z",
    updatedAt: "2023-07-24T14:42:02.516Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/vai-in-campeggio-b5fe9444c5dc.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#e91ed06f-7b21-4430-aabb-408546b9bcb3#stage#PUBLISHED",
          title: "Vai in campeggio",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#e91ed06f-7b21-4430-aabb-408546b9bcb3#stage#PUBLISHED",
          title: "Allez en camping",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#e91ed06f-7b21-4430-aabb-408546b9bcb3#stage#PUBLISHED",
          title: "Ve de acampada  ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#e91ed06f-7b21-4430-aabb-408546b9bcb3#stage#PUBLISHED",
          title: "Go camping",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#e91ed06f-7b21-4430-aabb-408546b9bcb3#stage#PUBLISHED",
          title: "Gehen sie campen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catTravel",
      name: "Travel",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 124.72,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "4f6be153-4321-4206-878d-5c58a839cbdf",
    createdAt: "2023-04-06T09:04:36.377Z",
    updatedAt: "2023-07-24T14:41:37.264Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/unisciti-a-organizzazione-per-ambiente-fec6e7d9a6fc.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#4f6be153-4321-4206-878d-5c58a839cbdf#stage#PUBLISHED",
          title: "Unisciti a un'organizzazione per l'ambiente",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#4f6be153-4321-4206-878d-5c58a839cbdf#stage#PUBLISHED",
          title: "Rejoignez une association pour l’environnement",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#4f6be153-4321-4206-878d-5c58a839cbdf#stage#PUBLISHED",
          title: "Unete a una organización para el medioambiente",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#4f6be153-4321-4206-878d-5c58a839cbdf#stage#PUBLISHED",
          title: "Join an environmental organization",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#4f6be153-4321-4206-878d-5c58a839cbdf#stage#PUBLISHED",
          title: "Treten sie einer umweltorganisation bei",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "870ac7f5-e592-45dc-b9cc-bfb6a62864f1",
    createdAt: "2023-04-06T09:12:22.023Z",
    updatedAt: "2023-07-24T14:41:24.450Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/esponi-la-bandiera-degli-sdgs-d540030e310c.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#870ac7f5-e592-45dc-b9cc-bfb6a62864f1#stage#PUBLISHED",
          title: "Esponi la bandiera degli SDGs",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#870ac7f5-e592-45dc-b9cc-bfb6a62864f1#stage#PUBLISHED",
          title: "Faites flotter le drapeau des ODD",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#870ac7f5-e592-45dc-b9cc-bfb6a62864f1#stage#PUBLISHED",
          title: "Ondea la bandera de los ODS ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#870ac7f5-e592-45dc-b9cc-bfb6a62864f1#stage#PUBLISHED",
          title: "Show the SDGs flag",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#870ac7f5-e592-45dc-b9cc-bfb6a62864f1#stage#PUBLISHED",
          title: "Hissen sie die flagge der SDGs",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "0fe99982-dcbd-49a0-a324-bf5d8c482a77",
    createdAt: "2023-04-05T14:49:17.461Z",
    updatedAt: "2023-07-24T14:41:15.561Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-un-elettrodomestico-usato-1a88cd5b93f8.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#0fe99982-dcbd-49a0-a324-bf5d8c482a77#stage#PUBLISHED",
          title: "Scegli un elettrodomestico usato",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#0fe99982-dcbd-49a0-a324-bf5d8c482a77#stage#PUBLISHED",
          title: "Choisissez un electromenager d’occasion",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#0fe99982-dcbd-49a0-a324-bf5d8c482a77#stage#PUBLISHED",
          title: "Elige electrodomésticos de segunda mano ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#0fe99982-dcbd-49a0-a324-bf5d8c482a77#stage#PUBLISHED",
          title: "Choose a used household appliance",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#0fe99982-dcbd-49a0-a324-bf5d8c482a77#stage#PUBLISHED",
          title: "Wählen sie ein gebrauchtes gerät ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 172.5,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "abaf9ed4-57a6-4bac-ae4d-d82f97509d29",
    createdAt: "2023-04-06T12:26:41.959Z",
    updatedAt: "2023-07-24T14:41:07.069Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/dona-esperienza-sostenibile-c86e068c04fb.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#abaf9ed4-57a6-4bac-ae4d-d82f97509d29#stage#PUBLISHED",
          title: "Regala un'esperienza sostenibile",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#abaf9ed4-57a6-4bac-ae4d-d82f97509d29#stage#PUBLISHED",
          title: "Offrez une expérience durable",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#abaf9ed4-57a6-4bac-ae4d-d82f97509d29#stage#PUBLISHED",
          title: "Regala una experiencia sostenible ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#abaf9ed4-57a6-4bac-ae4d-d82f97509d29#stage#PUBLISHED",
          title: "Gift a sustainable experience",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#abaf9ed4-57a6-4bac-ae4d-d82f97509d29#stage#PUBLISHED",
          title: "Schenken sie eine nachhaltige Erfahrung ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "a93cc4bc-4e74-4ab9-80c5-9b7024d42b11",
    createdAt: "2023-04-05T15:32:34.985Z",
    updatedAt: "2023-07-24T14:39:56.686Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-abiti-con-tessuti-riciclati-df0b67cff6a4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#a93cc4bc-4e74-4ab9-80c5-9b7024d42b11#stage#PUBLISHED",
          title: "Scegli abiti con tessuti riciclati",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#a93cc4bc-4e74-4ab9-80c5-9b7024d42b11#stage#PUBLISHED",
          title: "Choisissez des vêtements fabriqués avec des tissus recyclés",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#a93cc4bc-4e74-4ab9-80c5-9b7024d42b11#stage#PUBLISHED",
          title: "Elige prendas con tejidos reciclados",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#a93cc4bc-4e74-4ab9-80c5-9b7024d42b11#stage#PUBLISHED",
          title: "Choose clothes with recycled fabrics",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#a93cc4bc-4e74-4ab9-80c5-9b7024d42b11#stage#PUBLISHED",
          title: "Wählen sie kleidung aus recycelten stoffen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 6.4,
          energySaving: 0,
          waterSaving: 2245.5,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "fbfaa86a-3bed-49d7-be48-ee55c8f7f992",
    createdAt: "2023-04-06T12:54:12.790Z",
    updatedAt: "2023-07-24T14:39:46.262Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-capi-vegani-71bddc853db4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#fbfaa86a-3bed-49d7-be48-ee55c8f7f992#stage#PUBLISHED",
          title: "Scegli capi vegani",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#fbfaa86a-3bed-49d7-be48-ee55c8f7f992#stage#PUBLISHED",
          title: "Choisissez des vêtements vegan",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#fbfaa86a-3bed-49d7-be48-ee55c8f7f992#stage#PUBLISHED",
          title: "Elige prendas veganas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#fbfaa86a-3bed-49d7-be48-ee55c8f7f992#stage#PUBLISHED",
          title: "Choose vegan garments",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#fbfaa86a-3bed-49d7-be48-ee55c8f7f992#stage#PUBLISHED",
          title: "Wählen sie vegane Kleidungsstücke ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 9.39,
          energySaving: 0,
          waterSaving: 7113,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "d2068ec5-63bd-4b9d-a7a9-921bfc35cd53",
    createdAt: "2023-04-06T10:20:02.990Z",
    updatedAt: "2023-07-24T14:39:35.455Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/condividi-il-percorso-in-auto-060580c16c06.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#d2068ec5-63bd-4b9d-a7a9-921bfc35cd53#stage#PUBLISHED",
          title: "Condividi il percorso in auto",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#d2068ec5-63bd-4b9d-a7a9-921bfc35cd53#stage#PUBLISHED",
          title: "Faites du covoiturage",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#d2068ec5-63bd-4b9d-a7a9-921bfc35cd53#stage#PUBLISHED",
          title: "Comparte el viaje en coche",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#d2068ec5-63bd-4b9d-a7a9-921bfc35cd53#stage#PUBLISHED",
          title: "Carpool",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#d2068ec5-63bd-4b9d-a7a9-921bfc35cd53#stage#PUBLISHED",
          title: "Teilen sie die strecke mit dem auto",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 5.1,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "0f903da0-0c6f-46ea-b012-5f3c4b2f3e08",
    createdAt: "2023-04-06T12:28:28.675Z",
    updatedAt: "2023-07-24T14:39:10.846Z",
    isRepeatable: true,
    image: "https://cdn.aworld.click/action/2023/04/elettrico-ec5210983a1d.svg",
    featured: true,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#0f903da0-0c6f-46ea-b012-5f3c4b2f3e08#stage#PUBLISHED",
          title: "Scegli l'elettrico invece della benzina",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#0f903da0-0c6f-46ea-b012-5f3c4b2f3e08#stage#PUBLISHED",
          title: "Choisissez l'électrique plutôt que l’essence",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#0f903da0-0c6f-46ea-b012-5f3c4b2f3e08#stage#PUBLISHED",
          title: "Elige eléctrico en lugar de gasolina",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#0f903da0-0c6f-46ea-b012-5f3c4b2f3e08#stage#PUBLISHED",
          title: "Choose an electric vehicle instead of a gas one",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#0f903da0-0c6f-46ea-b012-5f3c4b2f3e08#stage#PUBLISHED",
          title: "Wählen sie strom statt benzin ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.2,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "0b290073-6b58-47f4-a78c-466687eff6bd",
    createdAt: "2023-04-05T15:39:36.179Z",
    updatedAt: "2023-07-24T14:39:01.042Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-i-prodotti-locali-d8012765ef87.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#0b290073-6b58-47f4-a78c-466687eff6bd#stage#PUBLISHED",
          title: "Scegli i prodotti locali",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#0b290073-6b58-47f4-a78c-466687eff6bd#stage#PUBLISHED",
          title: "Choisissez des produits locaux",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#0b290073-6b58-47f4-a78c-466687eff6bd#stage#PUBLISHED",
          title: "Elige productos locales ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#0b290073-6b58-47f4-a78c-466687eff6bd#stage#PUBLISHED",
          title: "Choose local products",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#0b290073-6b58-47f4-a78c-466687eff6bd#stage#PUBLISHED",
          title: "Wählen sie lokale produkte",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.04,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "c5e94538-67af-4990-a3a6-0e380a64fae9",
    createdAt: "2023-04-05T15:24:39.449Z",
    updatedAt: "2023-07-24T14:38:51.592Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/mangia-un-pasto-vegano-6867976d3aa4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#c5e94538-67af-4990-a3a6-0e380a64fae9#stage#PUBLISHED",
          title: "Mangia un pasto vegano",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#c5e94538-67af-4990-a3a6-0e380a64fae9#stage#PUBLISHED",
          title: "Mangez un plat vegan ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#c5e94538-67af-4990-a3a6-0e380a64fae9#stage#PUBLISHED",
          title: "Come un menú vegano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#c5e94538-67af-4990-a3a6-0e380a64fae9#stage#PUBLISHED",
          title: "Eat a vegan meal",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#c5e94538-67af-4990-a3a6-0e380a64fae9#stage#PUBLISHED",
          title: "Essen sie eine vegane mahlzeit",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 6.74,
          energySaving: 0,
          waterSaving: 1034,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "98ab9142-3299-497b-8606-3b261a6577f8",
    createdAt: "2023-04-19T11:20:27.326Z",
    updatedAt: "2023-07-24T14:38:35.914Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-alimenti-coltivati-in-casa-c198356fda07.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#98ab9142-3299-497b-8606-3b261a6577f8#stage#PUBLISHED",
          title: "Scegli prodotti coltivati in casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#98ab9142-3299-497b-8606-3b261a6577f8#stage#PUBLISHED",
          title: "Choisissez des aliments cultivés à la maison",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#98ab9142-3299-497b-8606-3b261a6577f8#stage#PUBLISHED",
          title: "Escoge alimentos cultivados en casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#98ab9142-3299-497b-8606-3b261a6577f8#stage#PUBLISHED",
          title: "Choose home grown produce",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#98ab9142-3299-497b-8606-3b261a6577f8#stage#PUBLISHED",
          title: "Wählen sie lebensmittel aus eigenem anbau",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.4,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "d6a133c7-1db5-489a-9f1c-9e5ba10badcc",
    createdAt: "2023-04-05T15:10:41.255Z",
    updatedAt: "2023-07-24T14:38:26.734Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/cancella-i-file-4a089766fe22.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#d6a133c7-1db5-489a-9f1c-9e5ba10badcc#stage#PUBLISHED",
          title: "Cancella i file che non usi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#d6a133c7-1db5-489a-9f1c-9e5ba10badcc#stage#PUBLISHED",
          title: "Effacez les fichiers que vous n’utilisez pas",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#d6a133c7-1db5-489a-9f1c-9e5ba10badcc#stage#PUBLISHED",
          title: "Elimina los archivos que no usas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#d6a133c7-1db5-489a-9f1c-9e5ba10badcc#stage#PUBLISHED",
          title: "Delete files you don't use",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#d6a133c7-1db5-489a-9f1c-9e5ba10badcc#stage#PUBLISHED",
          title: "Löschen sie nicht benötigte dateien",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.015,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "3d4d2630-ce55-4d3d-aa4f-7ec2a0736e86",
    createdAt: "2023-04-05T15:55:22.573Z",
    updatedAt: "2023-07-24T14:38:16.988Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/condividi-tramite-link-2186c9e1736a.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#3d4d2630-ce55-4d3d-aa4f-7ec2a0736e86#stage#PUBLISHED",
          title: "Condividi i file tramite link",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#3d4d2630-ce55-4d3d-aa4f-7ec2a0736e86#stage#PUBLISHED",
          title: "Partagez vos fichiers par lien ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#3d4d2630-ce55-4d3d-aa4f-7ec2a0736e86#stage#PUBLISHED",
          title: "Comparte el archivo en lugar de enviarlo",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#3d4d2630-ce55-4d3d-aa4f-7ec2a0736e86#stage#PUBLISHED",
          title: "Share files via links",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#3d4d2630-ce55-4d3d-aa4f-7ec2a0736e86#stage#PUBLISHED",
          title: "Tauschen sie dateien über links aus ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.46,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "110dac5d-feb7-45a9-9ebf-667228d40e29",
    createdAt: "2023-04-19T09:25:07.279Z",
    updatedAt: "2023-07-24T14:38:06.888Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-pulizia-nel-cloud-a2897aa44b3a.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#110dac5d-feb7-45a9-9ebf-667228d40e29#stage#PUBLISHED",
          title: "Fai pulizia nel cloud",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#110dac5d-feb7-45a9-9ebf-667228d40e29#stage#PUBLISHED",
          title: "Faites le ménage dans le cloud",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#110dac5d-feb7-45a9-9ebf-667228d40e29#stage#PUBLISHED",
          title: "Haz limpieza en la nube",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#110dac5d-feb7-45a9-9ebf-667228d40e29#stage#PUBLISHED",
          title: "Clean the cloud",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#110dac5d-feb7-45a9-9ebf-667228d40e29#stage#PUBLISHED",
          title: "Räumen sie in der cloud auf",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 2,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "ca3641b9-d618-4568-8bde-388d2f491300",
    createdAt: "2023-04-17T14:48:33.145Z",
    updatedAt: "2023-07-24T14:37:40.685Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/asciuga-i-capelli-senza-phon-44f42acbcedf.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#ca3641b9-d618-4568-8bde-388d2f491300#stage#PUBLISHED",
          title: "Asciuga i capelli senza phon ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#ca3641b9-d618-4568-8bde-388d2f491300#stage#PUBLISHED",
          title: "Séchez vos cheveux sans sèche-cheveux ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#ca3641b9-d618-4568-8bde-388d2f491300#stage#PUBLISHED",
          title: "Sécate el pelo al aire",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#ca3641b9-d618-4568-8bde-388d2f491300#stage#PUBLISHED",
          title: "Dry your hair without a hairdryer",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#ca3641b9-d618-4568-8bde-388d2f491300#stage#PUBLISHED",
          title: "Trocknen sie das haar ohne föhn",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.16,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "83bb4a40-577c-46da-a778-1941eafa7001",
    createdAt: "2023-04-19T09:48:03.553Z",
    updatedAt: "2023-07-24T14:37:23.063Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/stanza-a-25-gradi-in-estate-8ae880391094.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#83bb4a40-577c-46da-a778-1941eafa7001#stage#PUBLISHED",
          title: "Mantieni la stanza a 25.5°C in estate",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#83bb4a40-577c-46da-a778-1941eafa7001#stage#PUBLISHED",
          title: "Maintenez les pièces a une temperature de 25.5°C en été ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#83bb4a40-577c-46da-a778-1941eafa7001#stage#PUBLISHED",
          title: "Mantén una temperatura ambiente de 25.5°C en verano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#83bb4a40-577c-46da-a778-1941eafa7001#stage#PUBLISHED",
          title: "Keep the room at 78°F or 25.5°C in summer",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#83bb4a40-577c-46da-a778-1941eafa7001#stage#PUBLISHED",
          title: "Halten sie den raum im sommer auf 25,5°C",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.13,
          energySaving: 0.3,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "f54ad071-e454-4a59-b2fc-ebb29ff844ca",
    createdAt: "2023-04-19T09:52:03.391Z",
    updatedAt: "2023-07-24T14:35:23.055Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/abbassa-il-riscaldamento-ccde3e0d3e47.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#f54ad071-e454-4a59-b2fc-ebb29ff844ca#stage#PUBLISHED",
          title: "Abbassa il riscaldamento",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#f54ad071-e454-4a59-b2fc-ebb29ff844ca#stage#PUBLISHED",
          title: "Baissez le chauffage",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#f54ad071-e454-4a59-b2fc-ebb29ff844ca#stage#PUBLISHED",
          title: "Baja la calefacción ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#f54ad071-e454-4a59-b2fc-ebb29ff844ca#stage#PUBLISHED",
          title: "Turn the heat down",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#f54ad071-e454-4a59-b2fc-ebb29ff844ca#stage#PUBLISHED",
          title: "Senken sie die heizung",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 2.11,
          energySaving: 12,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "f5d96019-7d6d-4d4d-aabf-924d04d3ad5a",
    createdAt: "2023-04-19T10:06:22.554Z",
    updatedAt: "2023-07-24T14:34:49.972Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-di-fare-le-scale-5054d6112368.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#f5d96019-7d6d-4d4d-aabf-924d04d3ad5a#stage#PUBLISHED",
          title: "Scegli di fare le scale",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#f5d96019-7d6d-4d4d-aabf-924d04d3ad5a#stage#PUBLISHED",
          title: "Choisissez les escaliers ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#f5d96019-7d6d-4d4d-aabf-924d04d3ad5a#stage#PUBLISHED",
          title: "Escoge las escaleras",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#f5d96019-7d6d-4d4d-aabf-924d04d3ad5a#stage#PUBLISHED",
          title: "Choose to take the stairs",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#f5d96019-7d6d-4d4d-aabf-924d04d3ad5a#stage#PUBLISHED",
          title: "Nehmen sie die treppe",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.42,
          energySaving: 1,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "a2a0d104-730b-4a79-9877-281d4ba82d17",
    createdAt: "2023-04-19T10:09:51.992Z",
    updatedAt: "2023-07-24T14:34:39.098Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spegni-il-wi-fi-quando-non-serve-b9ebbbbb38a8.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#a2a0d104-730b-4a79-9877-281d4ba82d17#stage#PUBLISHED",
          title: "Spegni il wi-fi quando non serve",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#a2a0d104-730b-4a79-9877-281d4ba82d17#stage#PUBLISHED",
          title: "Éteignez le wi-fi quand vous n'en avez pas besoin",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#a2a0d104-730b-4a79-9877-281d4ba82d17#stage#PUBLISHED",
          title: "Apaga el wi-fi cuando no lo necesites",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#a2a0d104-730b-4a79-9877-281d4ba82d17#stage#PUBLISHED",
          title: "Turn off WI-FI when you don't need it",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#a2a0d104-730b-4a79-9877-281d4ba82d17#stage#PUBLISHED",
          title: "Schalten sie das wlan aus, wenn sie es nicht brauchen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.67,
          energySaving: 0.64,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "58232d62-053e-4bc1-af95-264dcdb45749",
    createdAt: "2023-04-19T11:21:13.843Z",
    updatedAt: "2023-07-24T14:34:19.621Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-la-doccia-invece-del-bagno-0782404ec700.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#58232d62-053e-4bc1-af95-264dcdb45749#stage#PUBLISHED",
          title: "Fai la doccia invece del bagno",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#58232d62-053e-4bc1-af95-264dcdb45749#stage#PUBLISHED",
          title: "Prenez une douche plutôt qu’un bain",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#58232d62-053e-4bc1-af95-264dcdb45749#stage#PUBLISHED",
          title: "Toma una ducha en lugar de un baño",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#58232d62-053e-4bc1-af95-264dcdb45749#stage#PUBLISHED",
          title: "Take a shower instead of a bath",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#58232d62-053e-4bc1-af95-264dcdb45749#stage#PUBLISHED",
          title: "Duschen Sie, anstatt zu baden",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 76,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "e8d3d003-1c79-47cd-b671-565812d74e01",
    createdAt: "2023-04-06T14:37:26.561Z",
    updatedAt: "2023-07-24T14:34:04.539Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/fai-volontariato-d825344fa1c4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#e8d3d003-1c79-47cd-b671-565812d74e01#stage#PUBLISHED",
          title: "做義工",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#e8d3d003-1c79-47cd-b671-565812d74e01#stage#PUBLISHED",
          title: "Fai volontariato",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#e8d3d003-1c79-47cd-b671-565812d74e01#stage#PUBLISHED",
          title: "Faites du volontariat",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#e8d3d003-1c79-47cd-b671-565812d74e01#stage#PUBLISHED",
          title: "Haz voluntariado",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#e8d3d003-1c79-47cd-b671-565812d74e01#stage#PUBLISHED",
          title: "Volunteer",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#e8d3d003-1c79-47cd-b671-565812d74e01#stage#PUBLISHED",
          title: "Arbeiten sie ehrenamtlich",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "f33d439a-59c9-4dd1-b2d7-51b27053f750",
    createdAt: "2023-04-06T12:43:23.659Z",
    updatedAt: "2023-07-24T14:33:52.468Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-il-pescato-certificato-67cd74802fe7.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#f33d439a-59c9-4dd1-b2d7-51b27053f750#stage#PUBLISHED",
          title: "選擇經過認證的魚",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#f33d439a-59c9-4dd1-b2d7-51b27053f750#stage#PUBLISHED",
          title: "Scegli il pescato certificato",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#f33d439a-59c9-4dd1-b2d7-51b27053f750#stage#PUBLISHED",
          title: "Choisissez du poisson certifié ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#f33d439a-59c9-4dd1-b2d7-51b27053f750#stage#PUBLISHED",
          title: "Escoge pescado certificado",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#f33d439a-59c9-4dd1-b2d7-51b27053f750#stage#PUBLISHED",
          title: "Choose certified fish",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#f33d439a-59c9-4dd1-b2d7-51b27053f750#stage#PUBLISHED",
          title: "Wählen sie zertifizierten fang",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "3fd3f514-76e0-427d-bab4-33096a839e44",
    createdAt: "2023-04-19T09:51:28.763Z",
    updatedAt: "2023-07-24T14:33:39.212Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/porta-a-casa-gli-avanzi-be7eb0b279e0.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#3fd3f514-76e0-427d-bab4-33096a839e44#stage#PUBLISHED",
          title: "將剩菜打包回家",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#3fd3f514-76e0-427d-bab4-33096a839e44#stage#PUBLISHED",
          title: "Porta a casa gli avanzi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#3fd3f514-76e0-427d-bab4-33096a839e44#stage#PUBLISHED",
          title: "Rapportez les restes a la maison",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#3fd3f514-76e0-427d-bab4-33096a839e44#stage#PUBLISHED",
          title: "Llévate las sobras a casa",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#3fd3f514-76e0-427d-bab4-33096a839e44#stage#PUBLISHED",
          title: "Bring home leftovers",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#3fd3f514-76e0-427d-bab4-33096a839e44#stage#PUBLISHED",
          title: "Nehmen Sie die Reste mit nach Hause",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.6,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "bca7f25b-21b9-4d89-9a59-ce85291ac4ce",
    createdAt: "2023-04-06T08:52:14.673Z",
    updatedAt: "2023-07-24T14:02:52.192Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-prodotti-naturali-per-le-tue-piante-92ecf4aa7f93.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#bca7f25b-21b9-4d89-9a59-ce85291ac4ce#stage#PUBLISHED",
          title: "使用天然產品來照顧植物",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#bca7f25b-21b9-4d89-9a59-ce85291ac4ce#stage#PUBLISHED",
          title: "Usa prodotti naturali per le tue piante",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#bca7f25b-21b9-4d89-9a59-ce85291ac4ce#stage#PUBLISHED",
          title: "Utilisez des produits naturels pour vos plantes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#bca7f25b-21b9-4d89-9a59-ce85291ac4ce#stage#PUBLISHED",
          title: "Usa productos naturales para tus plantas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#bca7f25b-21b9-4d89-9a59-ce85291ac4ce#stage#PUBLISHED",
          title: "Use natural products for your plants",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#bca7f25b-21b9-4d89-9a59-ce85291ac4ce#stage#PUBLISHED",
          title: "Verwenden sie natürliche Produkte für ihre pflanzen ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "6884bbfd-3876-42c0-9f51-a200d3d4b552",
    createdAt: "2023-04-19T11:46:48.456Z",
    updatedAt: "2023-07-24T13:38:38.170Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/condividi-i-tuoi-attrezzi-4b6301009566.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#6884bbfd-3876-42c0-9f51-a200d3d4b552#stage#PUBLISHED",
          title: "分享您的工具",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#6884bbfd-3876-42c0-9f51-a200d3d4b552#stage#PUBLISHED",
          title: "Condividi i tuoi attrezzi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#6884bbfd-3876-42c0-9f51-a200d3d4b552#stage#PUBLISHED",
          title: "Partagez vos outils",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#6884bbfd-3876-42c0-9f51-a200d3d4b552#stage#PUBLISHED",
          title: "Comparte las herramientas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#6884bbfd-3876-42c0-9f51-a200d3d4b552#stage#PUBLISHED",
          title: "Share your tools",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#6884bbfd-3876-42c0-9f51-a200d3d4b552#stage#PUBLISHED",
          title: "Teilen Sie Ihre Werkzeuge",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 10.79,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "cd4778d6-a492-4844-944a-f864711e0a3b",
    createdAt: "2023-04-19T11:40:51.788Z",
    updatedAt: "2023-07-24T13:37:55.654Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-di-acquistare-nei-negozi-1cfe58f35b53.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#cd4778d6-a492-4844-944a-f864711e0a3b#stage#PUBLISHED",
          title: "選擇店內購買",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#cd4778d6-a492-4844-944a-f864711e0a3b#stage#PUBLISHED",
          title: "Scegli di acquistare nei negozi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#cd4778d6-a492-4844-944a-f864711e0a3b#stage#PUBLISHED",
          title: "Choisissez de faire vos achats en magasin",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#cd4778d6-a492-4844-944a-f864711e0a3b#stage#PUBLISHED",
          title: "Elige comprar en tienda",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#cd4778d6-a492-4844-944a-f864711e0a3b#stage#PUBLISHED",
          title: "Choose to purchase in stores",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#cd4778d6-a492-4844-944a-f864711e0a3b#stage#PUBLISHED",
          title: "Kaufen Sie in Geschäften ein",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.103,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "5948ce34-c718-4187-985d-cc36e1e5b6bb",
    createdAt: "2023-04-19T11:26:53.432Z",
    updatedAt: "2023-07-24T13:36:57.542Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-i-prodotti-di-stagione-34f8ea2b37e9.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#5948ce34-c718-4187-985d-cc36e1e5b6bb#stage#PUBLISHED",
          title: "選擇當季產品",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#5948ce34-c718-4187-985d-cc36e1e5b6bb#stage#PUBLISHED",
          title: "Scegli i prodotti di stagione",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#5948ce34-c718-4187-985d-cc36e1e5b6bb#stage#PUBLISHED",
          title: "Choisissez des produits de saison",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#5948ce34-c718-4187-985d-cc36e1e5b6bb#stage#PUBLISHED",
          title: "Elige productos de temporada",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#5948ce34-c718-4187-985d-cc36e1e5b6bb#stage#PUBLISHED",
          title: "Choose seasonal products",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#5948ce34-c718-4187-985d-cc36e1e5b6bb#stage#PUBLISHED",
          title: "Wählen Sie saisonale Produkte",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.8,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "29f009ce-98d5-4bad-bb27-320fc075191a",
    createdAt: "2023-04-05T14:54:00.488Z",
    updatedAt: "2023-07-24T13:36:32.318Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/stendi-il-bucato-f3a11d8ceffe.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#29f009ce-98d5-4bad-bb27-320fc075191a#stage#PUBLISHED",
          title: "風乾衣物",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#29f009ce-98d5-4bad-bb27-320fc075191a#stage#PUBLISHED",
          title: "Stendi il bucato all’aria",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#29f009ce-98d5-4bad-bb27-320fc075191a#stage#PUBLISHED",
          title: "Etendez votre linge à l'air libre",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#29f009ce-98d5-4bad-bb27-320fc075191a#stage#PUBLISHED",
          title: "Tiende la colada al aire ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#29f009ce-98d5-4bad-bb27-320fc075191a#stage#PUBLISHED",
          title: "Air-dry your laundry",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#29f009ce-98d5-4bad-bb27-320fc075191a#stage#PUBLISHED",
          title: "Hängen sie die wäsche an der luft auf",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.84,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "6d27a188-1b5a-435b-b162-d5673958bab9",
    createdAt: "2023-04-19T11:36:04.410Z",
    updatedAt: "2023-07-24T13:36:09.117Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spostati-con-un-monopattino-elettrico-0cda2abec2a4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#6d27a188-1b5a-435b-b162-d5673958bab9#stage#PUBLISHED",
          title: "Spostati con un monopattino elettrico",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#6d27a188-1b5a-435b-b162-d5673958bab9#stage#PUBLISHED",
          title: "Déplacez-vous en trottinette electrique",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#6d27a188-1b5a-435b-b162-d5673958bab9#stage#PUBLISHED",
          title: "Muévete con el monopatín eléctrico",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#6d27a188-1b5a-435b-b162-d5673958bab9#stage#PUBLISHED",
          title: "Get around with an electric scooter",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#6d27a188-1b5a-435b-b162-d5673958bab9#stage#PUBLISHED",
          title: "Fahren Sie mit einem Elektroroller",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.19,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "5add1b08-c651-4309-8bb7-fc148a103473",
    createdAt: "2023-04-19T10:48:45.767Z",
    updatedAt: "2023-07-24T13:35:15.961Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-cosmetici-ricaricabili-c31a2757f934.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#5add1b08-c651-4309-8bb7-fc148a103473#stage#PUBLISHED",
          title: "Scegli cosmetici ricaricabili",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#5add1b08-c651-4309-8bb7-fc148a103473#stage#PUBLISHED",
          title: "Choisissez des cosmétiques rechargeables",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#5add1b08-c651-4309-8bb7-fc148a103473#stage#PUBLISHED",
          title: "Elige cosméticos rellenables",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#5add1b08-c651-4309-8bb7-fc148a103473#stage#PUBLISHED",
          title: "Choose refillable cosmetics",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#5add1b08-c651-4309-8bb7-fc148a103473#stage#PUBLISHED",
          title: "Wählen Sie nachfüllbare Kosmetika",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.08,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "54977351-b61d-409e-8b55-287cdd37a68b",
    createdAt: "2023-04-19T11:10:26.900Z",
    updatedAt: "2023-07-24T13:34:26.209Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-prodotti-in-scadenza-67222c8b6644.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#54977351-b61d-409e-8b55-287cdd37a68b#stage#PUBLISHED",
          title: "選擇即期食品",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#54977351-b61d-409e-8b55-287cdd37a68b#stage#PUBLISHED",
          title: "Scegli alimenti in scadenza",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#54977351-b61d-409e-8b55-287cdd37a68b#stage#PUBLISHED",
          title: "Choisissez des produits à dates courtes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#54977351-b61d-409e-8b55-287cdd37a68b#stage#PUBLISHED",
          title: "Elige productos a punto de caducar",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#54977351-b61d-409e-8b55-287cdd37a68b#stage#PUBLISHED",
          title: "Choose expiring food",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#54977351-b61d-409e-8b55-287cdd37a68b#stage#PUBLISHED",
          title: "Wählen sie auslaufende produkte",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catFood",
      name: "Food",
      foreColor: "#115740",
      backColor: "#EFFAF6",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.029,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "d180983e-c674-45a8-aac4-1446b893f5a7",
    createdAt: "2023-04-19T11:17:52.380Z",
    updatedAt: "2023-07-24T13:34:05.767Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/riutilizza-acqua-00c0664dd530.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#d180983e-c674-45a8-aac4-1446b893f5a7#stage#PUBLISHED",
          title: "重複利用水",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#d180983e-c674-45a8-aac4-1446b893f5a7#stage#PUBLISHED",
          title: "Riutilizza l'acqua",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#d180983e-c674-45a8-aac4-1446b893f5a7#stage#PUBLISHED",
          title: "Réutilisez l’eau",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#d180983e-c674-45a8-aac4-1446b893f5a7#stage#PUBLISHED",
          title: "Reutiliza el agua",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#d180983e-c674-45a8-aac4-1446b893f5a7#stage#PUBLISHED",
          title: "Reuse water",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#d180983e-c674-45a8-aac4-1446b893f5a7#stage#PUBLISHED",
          title: "Verwenden Sie das Wasser weiter",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 1,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "951ee6b6-94a3-46b0-87ed-aaac491f9b35",
    createdAt: "2023-04-06T10:00:47.523Z",
    updatedAt: "2023-07-24T13:33:48.397Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ripara-il-tuo-cellulare-c203d8086cc1.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#951ee6b6-94a3-46b0-87ed-aaac491f9b35#stage#PUBLISHED",
          title: "Ripara il tuo cellulare",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#951ee6b6-94a3-46b0-87ed-aaac491f9b35#stage#PUBLISHED",
          title: "Faites réparer votre téléphone portable",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#951ee6b6-94a3-46b0-87ed-aaac491f9b35#stage#PUBLISHED",
          title: "Repara tu móvil",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#951ee6b6-94a3-46b0-87ed-aaac491f9b35#stage#PUBLISHED",
          title: "Repair your mobile phone",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#951ee6b6-94a3-46b0-87ed-aaac491f9b35#stage#PUBLISHED",
          title: "Reparieren sie ihr handy",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 55,
          energySaving: 0,
          waterSaving: 1280,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "716004d4-a701-4a74-a614-0936fa9002c3",
    createdAt: "2023-04-05T15:44:08.583Z",
    updatedAt: "2023-07-24T13:33:15.583Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ripara-gli-elettrodomestici-912de012af37.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#716004d4-a701-4a74-a614-0936fa9002c3#stage#PUBLISHED",
          title: "Ripara gli elettrodomestici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#716004d4-a701-4a74-a614-0936fa9002c3#stage#PUBLISHED",
          title: "Reparez les electromenagers",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#716004d4-a701-4a74-a614-0936fa9002c3#stage#PUBLISHED",
          title: "Repara los electrodomésticos ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#716004d4-a701-4a74-a614-0936fa9002c3#stage#PUBLISHED",
          title: "Repair your household appliances",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#716004d4-a701-4a74-a614-0936fa9002c3#stage#PUBLISHED",
          title: "Reparieren sie haushaltsgeräte ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 345,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "de09f07e-ba06-462b-b11d-039c125f5b24",
    createdAt: "2023-04-19T11:31:25.931Z",
    updatedAt: "2023-07-24T13:29:28.954Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/spostati-con-una-bici-elettrica-3481d3e85e19.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#de09f07e-ba06-462b-b11d-039c125f5b24#stage#PUBLISHED",
          title: "Spostati con una bici elettrica",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#de09f07e-ba06-462b-b11d-039c125f5b24#stage#PUBLISHED",
          title: "Déplacez-vous en vélo électrique",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#de09f07e-ba06-462b-b11d-039c125f5b24#stage#PUBLISHED",
          title: "Muévete con la bicicleta eléctrica",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#de09f07e-ba06-462b-b11d-039c125f5b24#stage#PUBLISHED",
          title: "Get around with an electric bike",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#de09f07e-ba06-462b-b11d-039c125f5b24#stage#PUBLISHED",
          title: "Fahren Sie mit einem E-Bike",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 1.67,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "0652e8f2-3c16-4911-90b8-f48759ffe007",
    createdAt: "2023-04-05T15:17:16.796Z",
    updatedAt: "2023-07-24T13:29:10.936Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ripara-i-tuoi-mobili-63b712de60b4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#0652e8f2-3c16-4911-90b8-f48759ffe007#stage#PUBLISHED",
          title: "Ripara i tuoi mobili",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#0652e8f2-3c16-4911-90b8-f48759ffe007#stage#PUBLISHED",
          title: "Réparez vos meubles",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#0652e8f2-3c16-4911-90b8-f48759ffe007#stage#PUBLISHED",
          title: "Reparas tus muebles",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#0652e8f2-3c16-4911-90b8-f48759ffe007#stage#PUBLISHED",
          title: "Repair home furniture",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#0652e8f2-3c16-4911-90b8-f48759ffe007#stage#PUBLISHED",
          title: "Reparieren sie ihre möbel ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 200,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "749bf4c4-49b3-4fbd-a2fb-479cc317e172",
    createdAt: "2023-04-19T11:14:40.034Z",
    updatedAt: "2023-07-24T13:28:55.176Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/metti-i-piatti-in-lavastoviglie-senza-sciacquarli-967ae66defaf.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#749bf4c4-49b3-4fbd-a2fb-479cc317e172#stage#PUBLISHED",
          title: "將餐具放入洗碗機而無需沖洗",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#749bf4c4-49b3-4fbd-a2fb-479cc317e172#stage#PUBLISHED",
          title: "Metti i piatti in lavastoviglie senza sciacquarli",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#749bf4c4-49b3-4fbd-a2fb-479cc317e172#stage#PUBLISHED",
          title: "Mettez les assiettes dans le lave-vaisselle sans les rincer",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#749bf4c4-49b3-4fbd-a2fb-479cc317e172#stage#PUBLISHED",
          title: "Mete los platos en el lavavajillas sin enjuagarlos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#749bf4c4-49b3-4fbd-a2fb-479cc317e172#stage#PUBLISHED",
          title: "Put the dishes in the dishwasher without rinsing",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#749bf4c4-49b3-4fbd-a2fb-479cc317e172#stage#PUBLISHED",
          title: "Stellen Sie das Geschirr ungespült in die Spülmaschine",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWater",
      name: "Water",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 4,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "c02915a7-c2ad-4609-a142-9b44adb15d1e",
    createdAt: "2023-04-19T11:29:42.695Z",
    updatedAt: "2023-07-24T13:28:37.116Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/usa-acqua-fredda-per-lavare-i-piatti-4a2330eb8272.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#c02915a7-c2ad-4609-a142-9b44adb15d1e#stage#PUBLISHED",
          title: "Usa l’acqua fredda per lavare i piatti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#c02915a7-c2ad-4609-a142-9b44adb15d1e#stage#PUBLISHED",
          title: "Utilisez de l’eau froide pour faire la vaisselle",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#c02915a7-c2ad-4609-a142-9b44adb15d1e#stage#PUBLISHED",
          title: "Usa agua fría para lavar los platos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#c02915a7-c2ad-4609-a142-9b44adb15d1e#stage#PUBLISHED",
          title: "Use cold water to wash the dishes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#c02915a7-c2ad-4609-a142-9b44adb15d1e#stage#PUBLISHED",
          title: "Verwenden Sie zum Geschirrspülen kaltes Wasser",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.07,
          energySaving: 0.18,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "41221f4f-8dec-4b17-872e-de55bc885aaa",
    createdAt: "2023-04-05T16:00:57.883Z",
    updatedAt: "2023-07-24T13:25:03.001Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ripara-i-dispositivi-elettronici-b0f23a55e172.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#41221f4f-8dec-4b17-872e-de55bc885aaa#stage#PUBLISHED",
          title: "Ripara i dispositivi elettronici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#41221f4f-8dec-4b17-872e-de55bc885aaa#stage#PUBLISHED",
          title: "Réparez vos appareils électroniques ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#41221f4f-8dec-4b17-872e-de55bc885aaa#stage#PUBLISHED",
          title: "Repara los dispositivos electrónicos ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#41221f4f-8dec-4b17-872e-de55bc885aaa#stage#PUBLISHED",
          title: "Repair electronic devices",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#41221f4f-8dec-4b17-872e-de55bc885aaa#stage#PUBLISHED",
          title: "Reparieren sie elektronische geräte",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 205,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "a99f7ab9-6303-4146-b0b7-31a8614919c5",
    createdAt: "2023-04-06T07:52:21.129Z",
    updatedAt: "2023-07-24T13:24:43.412Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ripara-i-tuoi-abiti-84de71b03ad2.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#a99f7ab9-6303-4146-b0b7-31a8614919c5#stage#PUBLISHED",
          title: "Ripara i tuoi abiti",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#a99f7ab9-6303-4146-b0b7-31a8614919c5#stage#PUBLISHED",
          title: "Raccommodez vos vêtements ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#a99f7ab9-6303-4146-b0b7-31a8614919c5#stage#PUBLISHED",
          title: "Arregla tus prendas",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#a99f7ab9-6303-4146-b0b7-31a8614919c5#stage#PUBLISHED",
          title: "Repair your clothes",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#a99f7ab9-6303-4146-b0b7-31a8614919c5#stage#PUBLISHED",
          title: "Reparieren sie ihre Kleidung ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 20.04,
          energySaving: 0,
          waterSaving: 3781,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "59556aa2-c1f6-46b6-bfc1-610d48a165b2",
    createdAt: "2023-04-06T08:08:13.572Z",
    updatedAt: "2023-07-24T13:24:25.901Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/prendi-il-treno-invece-di-auto-384e0ea5aee6.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#59556aa2-c1f6-46b6-bfc1-610d48a165b2#stage#PUBLISHED",
          title: "Prendi il treno invece dell’auto",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#59556aa2-c1f6-46b6-bfc1-610d48a165b2#stage#PUBLISHED",
          title: "Prenez le train au lieu de la voiture ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#59556aa2-c1f6-46b6-bfc1-610d48a165b2#stage#PUBLISHED",
          title: "Coge el tren en lugar del coche ",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#59556aa2-c1f6-46b6-bfc1-610d48a165b2#stage#PUBLISHED",
          title: "Take the train instead of the car",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#59556aa2-c1f6-46b6-bfc1-610d48a165b2#stage#PUBLISHED",
          title: "Nehmen sie den zug statt des autos",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catMobility",
      name: "Mobility",
      foreColor: "#74000E",
      backColor: "#FEEDEA",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 6.65,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "cd5ccbda-5233-402e-a88d-c1a09d430bbf",
    createdAt: "2023-04-06T11:54:09.385Z",
    updatedAt: "2023-07-24T13:24:01.378Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/investi-nei-fondi-esg-ba43144ddb29.svg",
    featured: true,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#cd5ccbda-5233-402e-a88d-c1a09d430bbf#stage#PUBLISHED",
          title: "Investi nei fondi ESG",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#cd5ccbda-5233-402e-a88d-c1a09d430bbf#stage#PUBLISHED",
          title: "Investissez dans les fonds ESG",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#cd5ccbda-5233-402e-a88d-c1a09d430bbf#stage#PUBLISHED",
          title: "Invierte en fondos ESG",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#cd5ccbda-5233-402e-a88d-c1a09d430bbf#stage#PUBLISHED",
          title: "Invest in ESG funds",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#cd5ccbda-5233-402e-a88d-c1a09d430bbf#stage#PUBLISHED",
          title: "Investieren sie in ESG-Fonds ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "648ebbef-9027-408a-9023-ee2e9d3c0d8c",
    createdAt: "2023-04-06T15:03:43.017Z",
    updatedAt: "2023-07-24T13:23:39.214Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-il-delivery-con-un-mezzo-elettrico-3fac23d7d1b4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#648ebbef-9027-408a-9023-ee2e9d3c0d8c#stage#PUBLISHED",
          title: "Scegli il delivery con un mezzo elettrico",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#648ebbef-9027-408a-9023-ee2e9d3c0d8c#stage#PUBLISHED",
          title: "Choisissez un livreur avec transport électrique",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#648ebbef-9027-408a-9023-ee2e9d3c0d8c#stage#PUBLISHED",
          title: "Elige entrega con vehículo eléctrico",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#648ebbef-9027-408a-9023-ee2e9d3c0d8c#stage#PUBLISHED",
          title: "Choose delivery with an electric vehicle",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#648ebbef-9027-408a-9023-ee2e9d3c0d8c#stage#PUBLISHED",
          title: "Wählen sie die lieferung mit einem elektrofahrzeug",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.36,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "38f52469-d826-4d27-afe5-46104acb60c6",
    createdAt: "2023-04-05T15:15:48.662Z",
    updatedAt: "2023-07-24T13:23:14.471Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-un-telefono-ricondizionato-3a44528426d4.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#38f52469-d826-4d27-afe5-46104acb60c6#stage#PUBLISHED",
          title: "Scegli un telefono ricondizionato",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#38f52469-d826-4d27-afe5-46104acb60c6#stage#PUBLISHED",
          title: "Choisissez un téléphone reconditionné",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#38f52469-d826-4d27-afe5-46104acb60c6#stage#PUBLISHED",
          title: "Elige un móvil reacondicionado",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#38f52469-d826-4d27-afe5-46104acb60c6#stage#PUBLISHED",
          title: "Choose a refurbished phone",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#38f52469-d826-4d27-afe5-46104acb60c6#stage#PUBLISHED",
          title: "Wählen sie ein generalüberholtes handy",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 55,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "3a53f0b5-9494-46a8-b256-f77d9e0f1b10",
    createdAt: "2023-04-05T14:48:20.262Z",
    updatedAt: "2023-07-24T13:22:56.482Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-mobili-di-seconda-mano-8b266efbd69e.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#3a53f0b5-9494-46a8-b256-f77d9e0f1b10#stage#PUBLISHED",
          title: "Scegli mobili di seconda mano",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#3a53f0b5-9494-46a8-b256-f77d9e0f1b10#stage#PUBLISHED",
          title: "Choisissez des meubles d'occasion",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#3a53f0b5-9494-46a8-b256-f77d9e0f1b10#stage#PUBLISHED",
          title: "Escoge muebles de segunda mano",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#3a53f0b5-9494-46a8-b256-f77d9e0f1b10#stage#PUBLISHED",
          title: "Choose second-hand furniture",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#3a53f0b5-9494-46a8-b256-f77d9e0f1b10#stage#PUBLISHED",
          title: "Wählen sie gebrauchte möbel ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 220,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "17d34997-71dc-46ac-9237-dce6988cccbd",
    createdAt: "2023-04-05T15:06:59.663Z",
    updatedAt: "2023-07-24T13:22:31.733Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scegli-un-dispositivo-elettronico-ricondizionato-41f2c7ecd524.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#17d34997-71dc-46ac-9237-dce6988cccbd#stage#PUBLISHED",
          title: "Scegli un dispositivo elettronico ricondizionato",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#17d34997-71dc-46ac-9237-dce6988cccbd#stage#PUBLISHED",
          title: "Choisissez un appareils électronique reconditionne ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#17d34997-71dc-46ac-9237-dce6988cccbd#stage#PUBLISHED",
          title: "Elige un dispositivo electrónico reacondicionado",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#17d34997-71dc-46ac-9237-dce6988cccbd#stage#PUBLISHED",
          title: "Choose a refurbished electronic device",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#17d34997-71dc-46ac-9237-dce6988cccbd#stage#PUBLISHED",
          title: "Wählen sie ein überholtes elektronisches gerät",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catPurchase",
      name: "Purchase",
      foreColor: "#6D258C",
      backColor: "#F8F0FC",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 205,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "686ff40d-3b15-452f-8081-2baf0545f99f",
    createdAt: "2023-04-19T10:17:42.389Z",
    updatedAt: "2023-07-24T13:21:45.893Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/disiscriviti-dalle-newsletter-che-non-leggi-33f8fc81a278.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#686ff40d-3b15-452f-8081-2baf0545f99f#stage#PUBLISHED",
          title: "Disiscriviti dalle newsletter che non leggi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#686ff40d-3b15-452f-8081-2baf0545f99f#stage#PUBLISHED",
          title: "Désinscrivez-vous aux newsletters que vous ne lisez pas",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#686ff40d-3b15-452f-8081-2baf0545f99f#stage#PUBLISHED",
          title: "Cancela las newsletter due no lees",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#686ff40d-3b15-452f-8081-2baf0545f99f#stage#PUBLISHED",
          title: "Unsubscribe from newsletters you don't read",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#686ff40d-3b15-452f-8081-2baf0545f99f#stage#PUBLISHED",
          title: "Melden sie sich von den newsletters ab, die sie nicht lesen",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.07,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "ca128370-0b59-407a-9185-acadc8116a7c",
    createdAt: "2023-04-19T11:08:06.075Z",
    updatedAt: "2023-07-24T13:21:10.861Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/raccogli-i-rifiuti-elettronici-8c19463bd94b.svg",
    featured: false,
    points: 1,
    difficultyLevel: "hard",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#ca128370-0b59-407a-9185-acadc8116a7c#stage#PUBLISHED",
          title: "Raccogli i rifiuti elettronici",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#ca128370-0b59-407a-9185-acadc8116a7c#stage#PUBLISHED",
          title: "Récupérez les déchets électroniques",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#ca128370-0b59-407a-9185-acadc8116a7c#stage#PUBLISHED",
          title: "Recoge los residuos electrónicos",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#ca128370-0b59-407a-9185-acadc8116a7c#stage#PUBLISHED",
          title: "Collect electronic waste",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#ca128370-0b59-407a-9185-acadc8116a7c#stage#PUBLISHED",
          title: "Sammeln Sie den Elektronikmüll",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWaste",
      name: "Waste",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0.93,
          energySaving: 2.2,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "c9379e07-6591-45b2-9603-0a0c2d2d53ca",
    createdAt: "2023-04-20T10:21:42.137Z",
    updatedAt: "2023-07-24T13:20:46.893Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/ricarica-i-dispositivi-al-90-2de955788734.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#c9379e07-6591-45b2-9603-0a0c2d2d53ca#stage#PUBLISHED",
          title: "Ricarica i dispositivi al 90%",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#c9379e07-6591-45b2-9603-0a0c2d2d53ca#stage#PUBLISHED",
          title: "Rechargez vos appareils a 90%",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#c9379e07-6591-45b2-9603-0a0c2d2d53ca#stage#PUBLISHED",
          title: "Recarga tus dispositivos al 90%",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#c9379e07-6591-45b2-9603-0a0c2d2d53ca#stage#PUBLISHED",
          title: "Charge your devices to 90%",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#c9379e07-6591-45b2-9603-0a0c2d2d53ca#stage#PUBLISHED",
          title: "Laden sie die geräte zu 90 prozent auf",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catEnergy",
      name: "Energy",
      foreColor: "#793F00",
      backColor: "#FDF7F1",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "26ee89ce-a06f-45aa-a495-35320b0b7adb",
    createdAt: "2023-04-06T08:29:23.254Z",
    updatedAt: "2023-07-24T13:20:30.766Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/pianta-un-albero-o-un-fiore-ca80761f07b3.svg",
    featured: false,
    points: 1,
    difficultyLevel: "medium",
    document: {
      items: [
        {
          default: false,
          lang: "zh_tw",
          parentId: "#id#26ee89ce-a06f-45aa-a495-35320b0b7adb#stage#PUBLISHED",
          title: "種植一棵樹或一朵花",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "it",
          parentId: "#id#26ee89ce-a06f-45aa-a495-35320b0b7adb#stage#PUBLISHED",
          title: "Pianta un albero o un fiore",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#26ee89ce-a06f-45aa-a495-35320b0b7adb#stage#PUBLISHED",
          title: "Plantez un arbre ou une fleur ",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#26ee89ce-a06f-45aa-a495-35320b0b7adb#stage#PUBLISHED",
          title: "Planta un árbol o una flor",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#26ee89ce-a06f-45aa-a495-35320b0b7adb#stage#PUBLISHED",
          title: " Plant a tree or a flower",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#26ee89ce-a06f-45aa-a495-35320b0b7adb#stage#PUBLISHED",
          title: "Pflanzen sie einen baum oder eine blume ",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "a665c1c9-e46e-4332-b8a2-b0544ee43f9f",
    createdAt: "2023-04-06T14:46:17.208Z",
    updatedAt: "2023-07-24T13:15:12.676Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/04/scrivi-5-cose-per-cui-sei-grato-oggi-9b6a8b1db585.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#a665c1c9-e46e-4332-b8a2-b0544ee43f9f#stage#PUBLISHED",
          title: "Scrivi 5 cose per cui sei grato/a oggi",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "fr",
          parentId: "#id#a665c1c9-e46e-4332-b8a2-b0544ee43f9f#stage#PUBLISHED",
          title: "Ecrivez 5 choses qui vous ont rendu heureux aujourd’hui",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "es",
          parentId: "#id#a665c1c9-e46e-4332-b8a2-b0544ee43f9f#stage#PUBLISHED",
          title: "Escribe 5 cosas por las que estas agradecida/o hoy",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#a665c1c9-e46e-4332-b8a2-b0544ee43f9f#stage#PUBLISHED",
          title: "Write 5 things you are grateful for today",
          __typename: "ActionDocumentItem",
        },
        {
          default: false,
          lang: "de",
          parentId: "#id#a665c1c9-e46e-4332-b8a2-b0544ee43f9f#stage#PUBLISHED",
          title: "Schreiben sie 5 dinge auf, für die sie heute dankbar sind",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catWellness",
      name: "Environment",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
  {
    id: "45d3c610-b15b-44e6-adf6-61038705f715",
    createdAt: "2023-07-03T14:31:57.538Z",
    updatedAt: "2023-07-03T15:53:29.507Z",
    isRepeatable: true,
    image:
      "https://cdn.aworld.click/action/2023/07/share-your-progress-online-0c9700598765.svg",
    featured: false,
    points: 1,
    difficultyLevel: "easy",
    document: {
      items: [
        {
          default: false,
          lang: "it",
          parentId: "#id#45d3c610-b15b-44e6-adf6-61038705f715#stage#PUBLISHED",
          title: "Condividi i tuoi progressi online",
          __typename: "ActionDocumentItem",
        },
        {
          default: true,
          lang: "en",
          parentId: "#id#45d3c610-b15b-44e6-adf6-61038705f715#stage#PUBLISHED",
          title: "Share your progress online",
          __typename: "ActionDocumentItem",
        },
      ],
      __typename: "ActionDocument",
    },
    category: {
      id: "catSociety",
      name: "Social",
      foreColor: "#1C4E7D",
      backColor: "#F0F8FE",
      __typename: "Category",
    },
    savingMetrics: [
      {
        metrics: {
          co2Saving: 0,
          energySaving: 0,
          waterSaving: 0,
          __typename: "SavingMetrics",
        },
        __typename: "SavingMetricsRelation",
      },
    ],
    __typename: "Action",
  },
]

export default actionsList
