import { useContext } from "react"
import {
  relativeHeight,
  relativeWidth,
  roundWithDecimalPlaces,
} from "../../service/utilities"
import Text from "../global/Text"
import { MainContext } from "../../controllers/main"

const LeaderboardAll = () => {
  const { data } = useContext(MainContext)

  return (
    <div
      style={{
        width: "100%",
        height: relativeHeight(616),
        borderRadius: relativeWidth(15),
        backgroundColor: "white",
        marginTop: relativeHeight(55),
        paddingInline: relativeWidth(18),
        display: "flex",
        flexDirection: "column",
      }}
    >
      {data.ranking.slice(3, 10).map((item, index) => (
        <ListItem
          key={index}
          index={index}
          name={item.first_name}
          impact={item.footprint}
        />
      ))}
      {/* render placeholders */}
      {Array.apply(null, Array(7 - data.ranking.slice(3, 10).length)).map(
        (item, index) => (
          <ListItemPlaceholder
            key={index}
            index={data.ranking.slice(3, 10).length + index}
          />
        )
      )}
    </div>
  )
}

const ListItem = ({
  index,
  name,
  impact,
}: {
  index: number
  name: string
  impact: number
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: relativeHeight(88),
        position: "relative",
        paddingLeft:
          index === 2 || index === 4 || index === 5
            ? relativeWidth(16)
            : index === 6
            ? relativeWidth(2)
            : relativeWidth(17),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: relativeWidth(23),
      }}
    >
      {index !== 0 ? (
        <div
          style={{
            width: "100%",
            height: 1,
            borderRadius: 100,
            backgroundColor: "#B2B2B2",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      ) : null}
      <Text fontSize={relativeWidth(28)} fontWeight={700}>
        {index + 4}
      </Text>
      <Text
        fontWeight={700}
        style={{
          maxWidth: "60%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </Text>
      <Text
        fontWeight={500}
        style={{ position: "absolute", right: relativeWidth(13) }}
      >
        {roundWithDecimalPlaces(impact, 1).toString().replace(".", ",")} kg
      </Text>
    </div>
  )
}

const ListItemPlaceholder = ({ index }: { index: number }) => {
  return (
    <div
      style={{
        width: "100%",
        height: relativeHeight(88),
        position: "relative",
        paddingLeft:
          index === 2 || index === 4 || index === 5
            ? relativeWidth(16)
            : index === 6
            ? relativeWidth(2)
            : relativeWidth(17),
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: relativeWidth(23),
      }}
    >
      {index !== 0 ? (
        <div
          style={{
            width: "100%",
            height: 1,
            borderRadius: 100,
            backgroundColor: "#B2B2B2",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      ) : null}
      <Text fontSize={relativeWidth(28)} fontWeight={700}>
        {index + 4}
      </Text>
    </div>
  )
}

export default LeaderboardAll
